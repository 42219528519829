export const RISK_LEVEL = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const MARKS_SLIDER = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "20%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const OPERATION_TYPES = {
  BUY_TOKEN: "BUY_TOKEN",
  SELL_TOKEN: "SELL_TOKEN",
};
