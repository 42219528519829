import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Button } from "../../../theme/components/Button";
import { roundValue } from "../../../utils/functions/roundValue";
import { getTicker } from "../../../utils/functions/getTicker";
import { OPERATION_TYPES } from "../../../utils/constants/capital";
import { openTradeDialog } from "../../../redux/actions/dialogActions";
import Risk from "../../../theme/components/Risk";

const Fund = ({ fund }) => {
  const classes = useStylesCard();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, allTickerSettings } = useSelector(({ wallets }) => wallets);

  const handleOpenTradeDialog = (type) => {
    dispatch(
      openTradeDialog({
        typeTrade: type,
        item: fund,
        settings: walletSettings,
        userBalanceAsset: userBalanceAsset,
        isIndexes: false,
      })
    );
  };

  const walletSettings = useMemo(() => {
    return allTickerSettings?.find((i) => i.ticker === fund?.asset);
  }, [allTickerSettings, fund]);

  const userBalanceAsset = useMemo(() => {
    return +data?.find((i) => i.ticker === fund?.asset)?.balance;
  }, [data, fund]);

  return (
    <>
      <Paper elevation={4} className={classes.root}>
        <Grid container >
          <Grid
            container
            className={classes.head}
            onClick={() => history.push(`/capital/fund/${fund?.id}`)}
          >
            <Grid item xs={7} sm={6} container direction="row" className={classes.titleBox}>
              <Typography variant={"h2"} className={classes.title}>
                {fund?.token}
              </Typography>
              <Typography variant={"h4"} className={classes.name}>
                {fund?.name.slice(0, 6)}
              </Typography>
            </Grid>
            <Grid item xs={5} sm={6} container alignItems="flex-end" justifyContent="center">
              <Risk risk={fund?.riskLevel} />
            </Grid>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography variant={"body1"} color="textPrimary">
              {fund?.totalReward} $
            </Typography>
            <Typography variant={"caption"} color="textSecondary">
              {"Total Rewards"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body1"} color="textPrimary">
              {fund?.lastReward} $
            </Typography>
            <Typography variant={"caption"} noWrap color="textSecondary">
              {"Last Rewards"}
            </Typography>
            <br />
            <Typography variant={"caption"} color="textSecondary">
              {fund?.lastRewardTime &&
                moment(fund?.lastRewardTime).format("DD/MM/yyyy")}
            </Typography>
          </Grid> */}
          <Grid item sm={3} xs={4}>
            <Typography className={classes.labelText} variant={"caption"} color="textSecondary">
              {"Coin price"}
            </Typography>
            <Typography className={classes.valueText} variant={"subtitle2"} color="textPrimary">
              <span className={classes.numberText}>{+fund?.price}</span>{" "}{getTicker(fund?.asset)}
              {/* <span className={classes.percent}>6.4%</span> */}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} alignItems="center" container direction="column">
            <Typography className={classes.labelText} variant={"caption"} color="textSecondary">
              {"Market cap."}
            </Typography>
            <Typography className={classes.valueText} variant={"subtitle2"} color="textPrimary">
              <span className={classes.numberText}>{fund?.capitalization ? roundValue(fund?.capitalization, walletSettings?.minScale) : '...'}{" "}</span>
              {getTicker(fund?.asset)}
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography variant={"caption"} color="textSecondary">
              {"Current balance"}
            </Typography>
            <div className={classes.row}>
              <Typography variant={"subtitle2"} color="textPrimary">
                {+fund?.balance}
              </Typography>
              <div className={clsx(classes.token, classes[fund?.riskLevel])}>
                {fund?.token.slice(0, 3)}
              </div>
            </div>
          </Grid> */}
          <Grid item sm={4} xs={4} alignItems="center" container direction="column">
            <Typography className={classes.labelText} variant={"caption"} color="textSecondary">
              {"APY"}
            </Typography>
            <Typography className={classes.valueText} variant={"subtitle2"} color="textPrimary">
              <span className={classes.numberText}>{Number(fund?.apy || 0)}</span>{" "}%
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.contentBox}>
          <Grid item>
            <Button
              className={clsx(classes.button, classes.btnLabelColor)}
              variant="contained"
              onClick={() => handleOpenTradeDialog(OPERATION_TYPES.BUY_TOKEN)}
            >
              {"INVEST"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={clsx(classes.button, classes.btnLabelColor)}
              variant="contained"
              onClick={() => handleOpenTradeDialog(OPERATION_TYPES.SELL_TOKEN)}
            >
              {"SALE REQUEST"}
            </Button>
          </Grid>
        </Grid>
      </Paper >
    </>
  );
};

export default Fund;

export const useStylesCard = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: "100%",
    padding: 24,
    borderRadius: 12,
    background: theme.palette.background.paper,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignSelf: 'baseline',
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column'
    },
  },
  head: {
    marginBottom: 20,
    cursor: 'pointer',
  },
  percent: {
    background: theme.palette.success.main,
    padding: "2px 3px",
    fontSize: 6,
    borderRadius: 3,
    marginLeft: 20,
    marginTop: -4,
  },
  titleBox: {
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 44,
    textTransform: "uppercase",
    fontWeight: 800,
    minWidth: 90
  },
  name: {
    textTransform: 'uppercase',
    fontWeight: 700,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: '0',
    },
  },
  labelText: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  valueText: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 400,
    marginTop: 10,
  },
  numberText: {
    fontSize: 16,
    fontWeight: 700,
  },
  subtitleBox: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "21px",
    fontStyle: "normal",
    textTransform: "none",
  },
  logoBox: {
    width: 50,
    height: 50,
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: 800,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentBox: {
    display: "flex",
    flexDirection: 'column',
    alignContent: "space-around",
    flex: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  button: {
    width: "100%",
    "&.MuiButton-contained ": {
      minHeight: 48,
      minWidth: 200,
      fontSize: 10,
      borderRadius: 12,
      padding: 0,
      borderColor: theme.palette.primary.default,
    },
    "& .MuiButton-label": {
      color: theme.palette.background.paper,
    },
  },
  btnLabelColor: {
    "& .MuiButton-label": {
      color: "#FFFFFF",
      fontSize: 16,
      textTransform: 'uppercase',
    },
  },
  LOW: {
    backgroundColor: "rgba(244, 229, 88, 1) !important",
  },
  MEDIUM: {
    backgroundColor: "rgba(248, 177, 51, 1) !important",
  },
  HIGH: {
    backgroundColor: "rgba(233, 90, 12, 1) !important",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  token: {
    width: 14,
    height: 14,
    fontSize: 5.5,
    color: theme.palette.secondary.main,
    borderRadius: "50%",
    fontWeight: 800,
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 4,
    marginTop: 2,
  },
}));
