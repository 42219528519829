import { hideLoader, showLoader } from "../actions/loadingActions";
import { enqueueSnackbar } from "../actions/notistackActions";
import i18next from "../../i18n.config";

export default function loadingMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    if (action.type.endsWith("STARTED")) {
      dispatch(showLoader());
    }
    if (action.type.endsWith("SUCCESS") || action.type.endsWith("FAILURE")) {
      dispatch(hideLoader());
    }
    if (
      action.type.endsWith("FAILURE") &&
      !action.payload?.error?.response?.data?.error_description?.includes(
        "Invalid access token"
      )
    ) {
      dispatch(
        enqueueSnackbar({
          message:
            typeof action.payload?.error?.response?.data === "string"
              ? i18next.t(action.payload?.error?.response?.data)
              : action.payload?.error?.response?.data.error === "invalid_token"
              ? i18next.t("Access token expired")
              : i18next.t(action.payload?.error?.response?.data?.title) ||
                i18next.t("Some error"),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            autoHideDuration: 3000,
          },
        })
      );
    }
    next(action);
  };
}
