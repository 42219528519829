import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
});

export const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      dark: "#181818",
      main: "#4AAF57",
      light: "#212328",
      lighter: '#2B2F3838',
      default: "#FFFFFF",
    },
    secondary: {
      main: "#8FA4FF",
      hover: "#2A2E3A",
      disabled: "#464B58",
    },
    text: {
      primary: "#ffffff",
      dark: "#2B2F38",
      darkLighter: "#878787",
      secondary: "#878787",
      tertiary: "#4AAF57",
      quaternary: "#8FA4FF",
      scrim: "rgba(0, 0, 0, 0.7)",
    },
    background: {
      main: "linear-gradient(180deg, #454B56 0%, #161820 100%)",
      header: "transparent",
      paper: "#212328",
      small: "#505563",
      default: "#000000",
      hover: "#2A2E3A",
      row: "rgba(43, 47, 56, 0.22)",
    },
    success: {
      main: "#49AB5B",
    },
    error: {
      main: "#FF2F51",
    },
    attention: {
      main: "#FF7F00",
    },
    action: {
      active: "#4AAF57",
      hover: "#5ADB6B",
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    htmlFontSize: 14,
    fontFamily: "Lato, Nunito Sans",
    h2: {
      fontSize: 34,
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "41px",
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "28px",
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "24px",
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "24px",
      [breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      fontStyle: "normal",
      lineHeight: "20px",
    },
    body1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "20px",
      [breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    body2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      [breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },

    subtitle1: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      fontStyle: "normal",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "20px",
    },
    caption: {
      fontSize: 11,
      fontWeight: 300,
    },
  },
});
