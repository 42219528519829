import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  // lighten,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { getWalletsList } from "../../../../redux/actions/walletsActions";
import {
  StyledSlider,
  ThumbComponent,
} from "../../../../theme/components/StyledSlider";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import DeleteConfirmDialog from "../../../../theme/dialogs/DeleteConfirmDialog";
import { MARKS_SLIDER } from "../../../../utils/constants/capital";
import { roundValue } from "../../../../utils/functions/roundValue";
import { getTicker } from "../../../../utils/functions/getTicker";
import { buyIndexToken } from "../../../../redux/actions/indexesActions";
import { buyFundToken } from "../../../../redux/actions/fundActions";

const Invest = ({ balance, item, settings, isIndexes }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [amountToken, setAmountToken] = useState(0);
  const [amount, setAmount] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const price = useMemo(
    () => (isIndexes ? item?.buyPrice : item?.price),
    [isIndexes, item]
  );

  const handleSliderChange = (event, newValue) => {
    event.stopPropagation();
    event.preventDefault();
    const maxPossibleNumberTokensBuy = Math.trunc(balance / price);
    if (!maxPossibleNumberTokensBuy) {
      setValue(0);
      setAmountToken(0);
      setAmount(0);
      return;
    }
    setValue(newValue);
    const valuePercent = 100 / maxPossibleNumberTokensBuy;
    setAmountToken(Math.trunc(newValue / valuePercent));
    setAmount(Math.trunc(newValue / valuePercent) * price);
  };

  const handleChangeAmount = (value) => {
    setAmountToken(value);
    setValue(value * (100 / Math.trunc(balance / price)));
    setAmount(value * price);
  };

  const handleConfirm = ({ accept }) => {
    if (accept && amount > 0) {
      dispatch(
        isIndexes
          ? buyIndexToken({ indexId: item?.id, amount })
          : buyFundToken({ fundId: item?.id, amount })
      ).then((response) => {
        response &&
          dispatch(dispatch(getWalletsList({ number: 0, size: 100 })));
        setAmount(0);
        setAmountToken(0);
        setValue(0);
      });
    }
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <DeleteConfirmDialog
        open={openConfirmDialog}
        title="Buy tokens"
        message={`Are you sure you want to buy ${amountToken} tokens ?`}
        handleClose={handleConfirm}
        btnOkText="Confirm"
        confirm={true}
      />
      <Paper elevation={4} className={classes.root}>
        <Grid container className={classes.head}>
          <Typography variant={"h4"} className={classes.title}>
            Buy
          </Typography>
        </Grid>

        <Grid container className={classes.contentBox}>
          <Grid item xs={12} className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Input
                  className={classes.input}
                  tabIndex="0"
                  variant="outlined"
                  label={"Amount token"}
                  name={"input"}
                  value={amountToken}
                  autoComplete="off"
                  onChange={({ target: { value } }) =>
                    /^[0-9]*$/.test(value) &&
                    value <= Math.trunc(balance / price) &&
                    !(
                      value.length > 1 &&
                      value[0] === "0" &&
                      value[1] !== "."
                    ) &&
                    handleChangeAmount(value)
                  }
                  onFocus={() => !amountToken && setAmountToken("")}
                  onBlur={() => !amountToken && setAmountToken(0)}

                // error={errors?.username?.invalid}
                // helperText={t(errors?.username?.errorMessage) || ""}
                />
              </Grid>
              <Grid container item xs={5} direction="column" alignItems="flex-start" justifyContent="space-around">
                <div className={clsx(classes.labelText)}>
                  {"You will be credited"}
                </div>
                <Typography className={clsx(classes.subtitleBox)}>
                  <span className={classes.numberText}>{roundValue(amount, settings?.minScale)}{" "}</span>
                  {getTicker(item?.asset)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.sliderContainer}>
              <StyledSlider
                ThumbComponent={ThumbComponent}
                aria-labelledby="track-false-slider"
                value={typeof value === "number" ? value : 0}
                onChange={handleSliderChange}
                marks={MARKS_SLIDER}
                step={100 / Math.trunc(balance / price)}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              className={classes.button}
              variant="contained"
              disabled={!+amountToken}
              onClick={() => setOpenConfirmDialog(true)}
            >
              {"INVEST"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Invest;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: '32px 24px',
    borderRadius: 12,
    background: `${theme.palette.background.paper} !important`,
    marginBottom: 24,
  },
  percent: {
    background: theme.palette.success.main,
    padding: "2px 3px",
    fontSize: 6,
    borderRadius: 3,
    marginLeft: 6,
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.text.tertiary,
    marginBottom: 20
  },
  subtitleBox: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "21px",
    fontStyle: "normal",
    textTransform: "none",
  },
  labelText: {
    fontSize: 12,
  },
  numberText: {
    fontSize: 24,
  },
  contentBox: {
  },
  section: {
    marginBottom: 12,
  },
  textBox: {
    display: "flex",
    alignItems: "center",
  },
  textBox_title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  textBox_img: {
    marginLeft: 6,
  },
  paper: {
    background: theme.palette.background.header,
    borderRadius: 6,
  },
  caption: {
    fontSize: 6,
    fontWeight: 400,
    lineHeight: "7px",
  },
  sliderContainer: {
    marginTop: 16,
    marginBottom: 16,
  },
  input: {
  },

  span: {
    fontSize: 9,
    fontWeight: 400,
    lineHeight: 11,
  },
  white: {
    color: "#FFFFFF",
  },
  button: {
    maxWidth: 120,
    alignSelf: 'flex-end',
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 120,
      fontSize: 10,
      padding: 0,
    },
    "& .MuiButton-label": {
      // color: theme.palette.background.paper,
    },
  },
}));
