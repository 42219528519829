import { INDEX } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
import { OPERATION_TYPES } from "../../utils/constants/capital";
import { closeTradeDialog, openSuccessTradeDialog } from "./dialogActions";
import { getWalletsList } from "./walletsActions";

// GET_ALL_INVESTMENT_INDEX

export const getAllInvestmentIndexAction = actionCreator(
  INDEX.GET_ALL_INVESTMENT_INDEX
);

export const getAllInvestmentIndex = () => {
  return async (dispatch) => {
    dispatch(getAllInvestmentIndexAction.started());
    api.investment.index
      .getAllInvestmentIndex()
      .then((res) => {
        dispatch(getAllInvestmentIndexAction.done(res.data));
      })
      .catch((error) => dispatch(getAllInvestmentIndexAction.failed(error)));
  };
};

// INDEX.DELETE_INDEX_TOKEN

export const deleteIndexTokenAction = actionCreator(INDEX.DELETE_INDEX_TOKEN);

export const deleteIndexToken = () => {
  return async (dispatch) => {
    dispatch(deleteIndexTokenAction.started());
    api.investment.index
      .deleteIndexToken()
      .then((res) => {
        dispatch(deleteIndexTokenAction.done(res.data));
      })
      .catch((error) => dispatch(deleteIndexTokenAction.failed(error)));
  };
};

// ESTIMATE_BUY_INDEX_TOKEN

export const estimateBuyIndexTokenAction = actionCreator(
  INDEX.ESTIMATE_BUY_INDEX_TOKEN
);

export const estimateBuyIndexToken = () => {
  return async (dispatch) => {
    dispatch(estimateBuyIndexTokenAction.started());
    api.investment.index
      .estimateBuyIndexToken()
      .then((res) => {
        dispatch(estimateBuyIndexTokenAction.done(res.data));
      })
      .catch((error) => dispatch(estimateBuyIndexTokenAction.failed(error)));
  };
};

// BUY_INDEX_TOKEN

export const buyIndexTokenAction = actionCreator(INDEX.BUY_INDEX_TOKEN);

export const buyIndexToken = (data) => {
  return async (dispatch) => {
    dispatch(buyIndexTokenAction.started());
    api.investment.index
      .buyIndexToken(data)
      .then((res) => {
        dispatch(buyIndexTokenAction.done(res.data));
        dispatch(closeTradeDialog());
        dispatch(getWalletsList({ number: 0, size: 100 }));
        dispatch(getAllInvestmentIndex());
        dispatch(
          openSuccessTradeDialog({
            typeTrade: OPERATION_TYPES.BUY_TOKEN,
            amount: res?.data?.tokenAmount,
          })
        );
      })
      .catch((error) => dispatch(buyIndexTokenAction.failed(error)));
  };
};

// ESTIMATE_SELL_INDEX_TOKEN

export const estimateSellIndexTokenAction = actionCreator(
  INDEX.ESTIMATE_SELL_INDEX_TOKEN
);

export const estimateSellIndexToken = () => {
  return async (dispatch) => {
    dispatch(estimateSellIndexTokenAction.started());
    api.investment.index
      .estimateSellIndexToken()
      .then((res) => {
        dispatch(estimateSellIndexTokenAction.done(res.data));
      })
      .catch((error) => dispatch(estimateSellIndexTokenAction.failed(error)));
  };
};

// SELL_INDEX_TOKEN

export const sellIndexTokenAction = actionCreator(INDEX.SELL_INDEX_TOKEN);

export const sellIndexToken = (data) => {
  return async (dispatch) => {
    dispatch(sellIndexTokenAction.started());
    api.investment.index
      .sellIndexToken(data)
      .then((res) => {
        dispatch(sellIndexTokenAction.done(res.data));
        dispatch(closeTradeDialog());
        dispatch(getWalletsList({ number: 0, size: 100 }));
        dispatch(getAllInvestmentIndex());
        dispatch(
          openSuccessTradeDialog({
            typeTrade: OPERATION_TYPES.SELL_TOKEN,
            amount: 0,
          })
        );
      })
      .catch((error) => dispatch(sellIndexTokenAction.failed(error)));
  };
};

// SWAP_INDEX_TOKEN

export const swapIndexTokenAction = actionCreator(INDEX.SWAP_INDEX_TOKEN);

export const swapIndexToken = () => {
  return async (dispatch) => {
    dispatch(swapIndexTokenAction.started());
    api.investment.index
      .swapIndexToken()
      .then((res) => {
        dispatch(swapIndexTokenAction.done(res.data));
      })
      .catch((error) => dispatch(swapIndexTokenAction.failed(error)));
  };
};

// GET_USER_INDEX_BALANCE

export const getUserIndexBalanceAction = actionCreator(
  INDEX.GET_USER_INDEX_BALANCE
);

export const getUserIndexBalance = () => {
  return async (dispatch) => {
    dispatch(getUserIndexBalanceAction.started());
    api.investment.index
      .getUserIndexBalance()
      .then((res) => {
        dispatch(getUserIndexBalanceAction.done(res.data));
      })
      .catch((error) => dispatch(getUserIndexBalanceAction.failed(error)));
  };
};

// GET_USER_INDEX_BALANCE_BY_TOKEN

export const getUserIndexBalanceByTokenAction = actionCreator(
  INDEX.GET_USER_INDEX_BALANCE_BY_TOKEN
);

export const getUserIndexBalanceByToken = () => {
  return async (dispatch) => {
    dispatch(getUserIndexBalanceByTokenAction.started());
    api.investment.index
      .getUserIndexBalanceByToken()
      .then((res) => {
        dispatch(getUserIndexBalanceByTokenAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserIndexBalanceByTokenAction.failed(error))
      );
  };
};

// GET_USER_INDEX_OPERATIONS_HISTORY

export const getUserIndexOperationsHistoryAction = actionCreator(
  INDEX.GET_USER_INDEX_OPERATIONS_HISTORY
);

export const getUserIndexOperationsHistory = () => {
  return async (dispatch) => {
    dispatch(getUserIndexOperationsHistoryAction.started());
    api.investment.index
      .getUserIndexOperationsHistory()
      .then((res) => {
        dispatch(getUserIndexOperationsHistoryAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserIndexOperationsHistoryAction.failed(error))
      );
  };
};

// GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN

export const getUserIndexOperationsHistoryByTokenAction = actionCreator(
  INDEX.GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN
);

export const getUserIndexOperationsHistoryByToken = (token, params) => {
  return async (dispatch) => {
    dispatch(getUserIndexOperationsHistoryByTokenAction.started());
    api.investment.index
      .getUserIndexOperationsHistoryByToken(token, params)
      .then((res) => {
        dispatch(getUserIndexOperationsHistoryByTokenAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserIndexOperationsHistoryByTokenAction.failed(error))
      );
  };
};

// GET_USER_INDEX_INVOICES

export const getUserIndexInvoicesAction = actionCreator(
  INDEX.GET_USER_INDEX_INVOICES
);

export const getUserIndexInvoices = () => {
  return async (dispatch) => {
    dispatch(getUserIndexInvoicesAction.started());
    api.investment.index
      .getUserIndexInvoices()
      .then((res) => {
        dispatch(getUserIndexInvoicesAction.done(res.data));
      })
      .catch((error) => dispatch(getUserIndexInvoicesAction.failed(error)));
  };
};

// GET_USER_INDEX_INVOICES_BY_TOKEN

export const getUserIndexInvoicesByTokenAction = actionCreator(
  INDEX.GET_USER_INDEX_INVOICES_BY_TOKEN
);

export const getUserIndexInvoicesByToken = () => {
  return async (dispatch) => {
    dispatch(getUserIndexInvoicesByTokenAction.started());
    api.investment.index
      .getUserIndexInvoicesByToken()
      .then((res) => {
        dispatch(getUserIndexInvoicesByTokenAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserIndexInvoicesByTokenAction.failed(error))
      );
  };
};

export const clearIndexesStatus = () => ({
  type: INDEX.CLEAR_INDEX_REQUEST_STATUS,
});

export const clearIndexesError = () => ({
  type: INDEX.CLEAR_INDEX_ERROR,
});
