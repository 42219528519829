import { makeStyles } from "@material-ui/core";
import RiskHeight from '../../assets/risk_height.svg'
import RiskLow from '../../assets/risk_low.svg'
import RiskMedium from '../../assets/risk_medium.svg'
import RiskMain from '../../assets/risk_main.svg'
import { useMemo } from "react";
import clsx from "clsx";

export const RiskSize = {
  'MEDIUM': 'MEDIUM',
  'LOW': 'LOW',
  'HIGH': 'HIGH'
}

const Risk = ({ risk, className, size }) => {
  const classes = useStyles();

  const imageUrl = useMemo(() => {
    if (risk === RiskSize.HIGH) {
      return RiskHeight
    } else if (risk === RiskSize.LOW) {
      return RiskLow
    } else if (risk === RiskSize.MEDIUM) {
      return RiskMedium
    } else {
      return RiskMain
    }
  }, [risk])
  return (
    <div className={clsx(classes.root, className)}>
      <img
        src={imageUrl}
        alt={'risk'}
        height={size ? size : 34}
        loading={"lazy"}
      />
    </div>
  );
};

export default Risk;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
}));
