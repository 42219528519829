// LOADING

import { generateActionTypes } from "./generateActionTypes";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// ACCOUNT

export const ACCOUNT_REGISTER_STARTED = "ACCOUNT_REGISTER_STARTED";
export const ACCOUNT_REGISTER_SUCCESS = "ACCOUNT_REGISTER_SUCCESS";
export const ACCOUNT_REGISTER_FAILURE = "ACCOUNT_REGISTER_FAILURE";
export const RESET_REGISTER_STATUS = "RESET_REGISTER_STATUS";

export const RESET_PASSWORD_INIT_STARTED = "RESET_PASSWORD_INIT_STARTED";
export const RESET_PASSWORD_INIT_SUCCESS = "RESET_PASSWORD_INIT_SUCCESS";
export const RESET_PASSWORD_INIT_FAILURE = "RESET_PASSWORD_INIT_FAILURE";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";
export const FINISH_RESET_PASSWORD_STARTED = "FINISH_RESET_PASSWORD_STARTED";
export const FINISH_RESET_PASSWORD_SUCCESS = "FINISH_RESET_PASSWORD_SUCCESS";
export const FINISH_RESET_PASSWORD_FAILURE = "FINISH_RESET_PASSWORD_FAILURE";

export const ACCOUNT_ACTIVATE_STARTED = "ACCOUNT_ACTIVATE_STARTED";
export const ACCOUNT_ACTIVATE_SUCCESS = "ACCOUNT_ACTIVATE_SUCCESS";
export const ACCOUNT_ACTIVATE_FAILURE = "ACCOUNT_ACTIVATE_FAILURE";

export const ACCOUNT_LOGIN_STARTED = "ACCOUNT_LOGIN_STARTED";
export const ACCOUNT_LOGIN_SUCCESS = "ACCOUNT_LOGIN_SUCCESS";
export const LOGIN_TWO_FA_SUCCESS = "LOGIN_TWO_FA_SUCCESS";
export const ACCOUNT_LOGIN_FAILURE = "ACCOUNT_LOGIN_FAILURE";

export const BACK_TO_MAIN = "BACK_TO_MAIN";
export const LOG_OUT = "LOG_OUT";

export const GET_ACCOUNT_DATA_STARTED = "GET_ACCOUNT_DATA_STARTED";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA_FAILURE = "GET_ACCOUNT_DATA_FAILURE";

export const UPDATE_ACCOUNT_STARTED = "UPDATE_ACCOUNT_STARTED";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const ENABLE_TWO_FA_STARTED = "ENABLE_TWO_FA_STARTED";
export const ENABLE_TWO_FA_SUCCESS = "ENABLE_TWO_FA_SUCCESS";
export const ENABLE_TWO_FA_FAILURE = "ENABLE_TWO_FA_FAILURE";

export const DISABLE_TWO_FA_STARTED = "DISABLE_TWO_FA_STARTED";
export const DISABLE_TWO_FA_SUCCESS = "DISABLE_TWO_FA_SUCCESS";
export const DISABLE_TWO_FA_FAILURE = "DISABLE_TWO_FA_FAILURE";

export const CHECK_IS_TWO_FA_STARTED = "CHECK_IS_TWO_FA_STARTED";
export const CHECK_IS_TWO_FA_SUCCESS = "CHECK_IS_TWO_FA_SUCCESS";
export const CHECK_IS_TWO_FA_FAILURE = "CHECK_IS_TWO_FA_FAILURE";

export const CHECK_CODE_TWO_FA_STARTED = "CHECK_CODE_TWO_FA_STARTED";
export const CHECK_CODE_TWO_FA_SUCCESS = "CHECK_CODE_TWO_FA_SUCCESS";
export const CHECK_CODE_TWO_FA_FAILURE = "CHECK_CODE_TWO_FA_FAILURE";

export const CHECK_INSTALL_TWO_FA_STARTED = "CHECK_INSTALL_TWO_FA_STARTED";
export const CHECK_INSTALL_TWO_FA_SUCCESS = "CHECK_INSTALL_TWO_FA_SUCCESS";
export const CHECK_INSTALL_TWO_FA_FAILURE = "CHECK_INSTALL_TWO_FA_FAILURE";

export const CLEAR_TWO_FA_QR_CODE = "CLEAR_TWO_FA_QR_CODE";

export const GET_ACCOUNT_PERMISSIONS_INFO = generateActionTypes(
  "GET_ACCOUNT_PERMISSIONS_INFO"
);

// WALLETS

export const GET_WALLETS_LIST_STARTED = "GET_WALLETS_LIST_STARTED";
export const GET_WALLETS_LIST_SUCCESS = "GET_WALLETS_LIST_SUCCESS";
export const GET_WALLETS_LIST_FAILURE = "GET_WALLETS_LIST_FAILURE";

export const SORT_WALLETS_LIST = "SORT_WALLETS_LIST";

export const FILTER_WALLETS_LIST = "FILTER_WALLETS_LIST";

export const SET_WALLETS_PARAMS = "SET_WALLETS_PARAMS";

export const GET_CURRENCIES_STARTED = "GET_CURRENCIES_STARTED";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";

export const GET_ALL_TICKER_SETTINGS_STARTED =
  "GET_ALL_TICKER_SETTINGS_STARTED";
export const GET_ALL_TICKER_SETTINGS_SUCCESS =
  "GET_ALL_TICKER_SETTINGS_SUCCESS";
export const GET_ALL_TICKER_SETTINGS_FAILURE =
  "GET_ALL_TICKER_SETTINGS_FAILURE";

// INDEX

export const INDEX = {
  GET_ALL_INVESTMENT_INDEX: generateActionTypes("GET_ALL_INVESTMENT_INDEX"),
  DELETE_INDEX_TOKEN: generateActionTypes("DELETE_INDEX_TOKEN"),
  ESTIMATE_BUY_INDEX_TOKEN: generateActionTypes("ESTIMATE_BUY_INDEX_TOKEN"),
  BUY_INDEX_TOKEN: generateActionTypes("BUY_INDEX_TOKEN"),
  ESTIMATE_SELL_INDEX_TOKEN: generateActionTypes("ESTIMATE_SELL_INDEX_TOKEN"),
  SELL_INDEX_TOKEN: generateActionTypes("SELL_INDEX_TOKEN"),
  SWAP_INDEX_TOKEN: generateActionTypes("SWAP_INDEX_TOKEN"),
  GET_USER_INDEX_BALANCE: generateActionTypes("GET_USER_INDEX_BALANCE"),
  GET_USER_INDEX_BALANCE_BY_TOKEN: generateActionTypes(
    "GET_USER_INDEX_BALANCE_BY_TOKEN"
  ),
  GET_USER_INDEX_OPERATIONS_HISTORY: generateActionTypes(
    "GET_USER_INDEX_OPERATIONS_HISTORY"
  ),
  GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN: generateActionTypes(
    "GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN"
  ),
  GET_USER_INDEX_INVOICES: generateActionTypes("GET_USER_INDEX_INVOICES"),
  GET_USER_INDEX_INVOICES_BY_TOKEN: generateActionTypes(
    "GET_USER_INDEX_INVOICES_BY_TOKEN"
  ),
  CLEAR_INDEX_REQUEST_STATUS: "CLEAR_INDEX_REQUEST_STATUS",
  CLEAR_INDEX__ERROR: "CLEAR_INDEX__ERROR",
};

// FUND

export const FUND = {
  GET_ALL_INVESTMENT_FUNDS: generateActionTypes("GET_ALL_INVESTMENT_FUNDS"),
  BUY_FUND_TOKEN: generateActionTypes("BUY_FUND_TOKEN"),
  SELL_FUND_TOKEN: generateActionTypes("SELL_FUND_TOKEN"),
  GET_USER_FUND_INVOICES: generateActionTypes("GET_USER_FUND_INVOICES"),
  GET_USER_FUND_INVOICES_BY_TOKEN: generateActionTypes(
    "GET_USER_FUND_INVOICES_BY_TOKEN"
  ),
  GET_USER_FUND_OPERATIONS_HISTORY: generateActionTypes(
    "GET_USER_FUND_OPERATIONS_HISTORY"
  ),
  GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN: generateActionTypes(
    "GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN"
  ),
  GET_USER_FUND_BALANCE: generateActionTypes("GET_USER_FUND_BALANCE"),
  GET_USER_FUND_BALANCE_BY_TOKEN: generateActionTypes(
    "GET_USER_FUND_BALANCE_BY_TOKEN"
  ),
  GET_USER_ALL_DIVIDENDS: generateActionTypes("GET_USER_ALL_DIVIDENDS"),
  GET_USER_ALL_DIVIDENDS_BY_TOKEN: generateActionTypes(
    "GET_USER_ALL_DIVIDENDS_BY_TOKEN"
  ),
  GET_USER_DIVIDENDS_STATISTIC_BY_FUND: generateActionTypes(
    "GET_USER_DIVIDENDS_STATISTIC_BY_FUND"
  ),
  CLEAR_FUND_REQUEST_STATUS: "CLEAR_FUND_REQUEST_STATUS",
  CLEAR_FUND__ERROR: "CLEAR_FUND__ERROR",
  SET_AMOUNT_BUY_TOKENS: "SET_AMOUNT_BUY_TOKENS",
};

// MODALS

export const OPEN_DIALOG = {
  // ADD_NEW_WALLET: "OPEN_DIALOG_ADD_NEW_WALLET",
  // RECEIVE: "OPEN_DIALOG_RECEIVE",
  // RECEIVE_CARD: "OPEN_DIALOG_RECEIVE_CARD",
  // CREATE_CARD: "OPEN_DIALOG_CREATE_CARD",
  // REPLENISHMENT_METHOD: "OPEN_REPLENISHMENT_METHOD_DIALOG",
  // WITHDRAWAL_METHOD: "OPEN_WITHDRAWAL_METHOD_DIALOG",
  INFO: "OPEN_INFO_DIALOG",
  TRADE: "OPEN_TRADE_DIALOG",
  SUCCESS_TRADE: "OPEN_SUCCESS_TRADE_DIALOG",
};

export const CLOSE_DIALOG = {
  // ADD_NEW_WALLET: "CLOSE_DIALOG_ADD_NEW_WALLET",
  // RECEIVE: "CLOSE_DIALOG_RECEIVE",
  // RECEIVE_CARD: "CLOSE_DIALOG_RECEIVE_CARD",
  // CREATE_CARD: "CLOSE_DIALOG_CREATE_CARD",
  // REPLENISHMENT_METHOD: "CLOSE_REPLENISHMENT_METHOD_DIALOG",
  // WITHDRAWAL_METHOD: "CLOSE_WITHDRAWAL_METHOD_DIALOG",
  INFO: "CLOSE_INFO_DIALOG",
  TRADE: "CLOSE_TRADE_DIALOG",
  SUCCESS_TRADE: "CLOSE_SUCCESS_TRADE_DIALOG",
};

// LANGUAGE

export const LANGUAGE = {
  SET_LANGUAGE: "SET_LANGUAGE",
  CLEAR_LANGUAGE: "CLEAR_LANGUAGE",
};
