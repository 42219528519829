import axios from "axios";
import * as R from "ramda";
import { BASE_URL, AUTH_TOKEN } from "../utils/constants/api";
import { store } from "../redux/store/store";
import { logOut } from "../redux/actions/accountActions";

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    // Handle request process
    this.client.interceptors.request.use(
      async (config) => {
        if (localStorage.getItem("access_token")) {
          return R.assocPath(
            ["headers", "common", "Authorization"],
            `Bearer ${localStorage.getItem("access_token")}`,
            config
          );
        } else {
          return config;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Handle response process
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.error_description?.includes(
            "Invalid refresh token"
          )
        ) {
        }
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;
          const refreshToken = localStorage.getItem("refresh_token");
          return new Promise(function (resolve, reject) {
            let formData = new FormData();
            formData.append("refresh_token", refreshToken);
            formData.append("grant_type", "refresh_token");
            const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString(
              "base64"
            );
            const headers = { Authorization: "Basic " + authToken };

            axios
              .post(`/api/accounts/oauth/token`, formData, {
                headers,
              })
              .then(({ data }) => {
                const access_token = data.access_token;
                const refresh_token = data.refresh_token;
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("refresh_token", refresh_token);
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${access_token}`;
                originalRequest.headers[
                  "Authorization"
                ] = `Bearer ${access_token}`;
                processQueue(null, access_token);
                resolve(axios(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
                store.dispatch(logOut());
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        return Promise.reject(error);
      }
    );
  }

  account = {
    register: (data) => this.client.post(`/api/accounts/register`, data),
    activate: (params) => this.client.get(`/api/accounts/activate`, { params }),
    login: (data, headers) =>
      this.client.post(`/api/accounts/oauth/token`, data, { headers }),
    refreshToken: (data, headers) =>
      this.client.post(`/api/accounts/oauth/token`, data, { headers }),
    // loginGoogle: (data) => this.client.post(`/auth/google/signin`, data),
    // loginApple: (data) => this.client.post(`/auth/apple/signin`, data),
    enableTwoFA: () => this.client.get(`/api/accounts/twofa/enable`),
    disableTwoFA: () => this.client.get(`/api/accounts/twofa/disable`),
    checkIsTwoFA: () => this.client.get(`/api/accounts/twofa/check-is`),
    checkCodeTwoFA: (data) =>
      this.client.post(`/api/accounts/twofa/check-code`, data),
    checkInstallTwoFA: (data) =>
      this.client.post(`/api/accounts/twofa/confirm`, data),
    logOut: () => this.client.post(`/api/accounts/current/logout`),
    getData: () => this.client.get(`/api/accounts/account/account`),
    permissionInfo: () => this.client.get(`/api/accounts/account/info`),
    update: (data) => this.client.patch(`/api/accounts/account`, data),
    initResetPassword: (data) =>
      this.client.post(`/api/accounts/account/reset-password/init`, data),
    finishResetPassword: (data) =>
      this.client.post(`/api/accounts/account/reset-password/finish`, data),
    changePassword: (data) =>
      this.client.post(`/api/accounts/account/change-password`, data),
  };
  wallets = {
    getList: (params) => this.client.get(`/api/finance/wallets`, { params }),
    getCurrencies: (params) =>
      this.client.get(`/api/finance/currencies`, { params }),
    getAllTickerSettings: () =>
      this.client.get(`/api/finance/currencies/settings`),
  };

  investment = {
    index: {
      getAllInvestmentIndex: () =>
        this.client.get(`/api/investment/index/detailed`),
      deleteEstimateBuyIndexToken: (indexId) =>
        this.client.delete(`/api/investment/index/${indexId}`),
      estimateBuyIndexToken: (data) =>
        this.client.post(
          `/api/investment/index/operations/buy_token/estimate`,
          data
        ),
      buyIndexToken: (data) =>
        this.client.post(`api/investment/index/operations/buy_token`, data),
      estimateSellIndexToken: (data) =>
        this.client.post(
          `/api/investment/index/operations/sell_token/estimate`,
          data
        ),
      sellIndexToken: (data) =>
        this.client.post(`api/investment/index/operations/sell_token`, data),
      swapIndexToken: (data) =>
        this.client.post(`api/investment/index/operations/swap_token`, data),
      getUserIndexBalance: () =>
        this.client.get(`/api/investment/index/balance`),
      getUserIndexBalanceByToken: (token) =>
        this.client.get(`/api/investment/index/balance/${token}`),
      getUserIndexOperationsHistory: (params) =>
        this.client.get(`/api/investment/index/operations/history`, { params }),
      getUserIndexOperationsHistoryByToken: (token, params) =>
        this.client.get(`/api/investment/index/operations/history/${token}`, {
          params,
        }),
      getUserIndexInvoices: () =>
        this.client.get(`/api/investment/index/invoice`),
      getUserIndexInvoicesByToken: (token, params) =>
        this.client.get(`/api/investment/index/invoice/${token}`, { params }),
    },
    fund: {
      getAllInvestmentFunds: (data) =>
        this.client.get(`/api/investment/fund/detailed`),
      buyFundToken: (data) =>
        this.client.post(`api/investment/fund/operations/buy_token`, data),
      sellFundToken: (data) =>
        this.client.post(`api/investment/fund/operations/sell_token`, data),
      getUserFundInvoices: () =>
        this.client.get(`/api/investment/fund/invoice`),
      getUserFundInvoicesByToken: (token, params) =>
        this.client.get(`/api/investment/fund/invoice/${token}`, { params }),
      getUserFundOperationsHistory: (params) =>
        this.client.get(`/api/investment/fund/operations/history`, { params }),
      getUserFundOperationsHistoryByToken: (token, params) =>
        this.client.get(`/api/investment/fund/operations/history/${token}`, {
          params,
        }),
      getUserFundBalance: () => this.client.get(`/api/investment/fund/balance`),
      getUserFundBalanceByToken: (token) =>
        this.client.get(`/api/investment/fund/balance/${token}`),
      getAllDividends: (params) =>
        this.client.get(`/api/investment/fund/dividends`, { params }),
      getAllDividendsByToken: (token) =>
        this.client.get(`/api/investment/fund/dividends/${token}`),
      getUserDividendsStatisticByFund: (token) =>
        this.client.get(`/api/investment/fund/dividends/${token}/statistic`),
    },
  };
}

const api = new Api();

export default api;
