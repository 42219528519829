import { FUND } from "../types";
import api from "../../services/api";
import { actionCreator } from "../actionCreator";
import { OPERATION_TYPES } from "../../utils/constants/capital";
import { openSuccessTradeDialog, closeTradeDialog } from "./dialogActions";
import { getWalletsList } from "./walletsActions";

// GET_ALL_INVESTMENT_FUNDS

export const getAllInvestmentFundsAction = actionCreator(
  FUND.GET_ALL_INVESTMENT_FUNDS
);

export const getAllInvestmentFunds = () => {
  return async (dispatch) => {
    dispatch(getAllInvestmentFundsAction.started());
    api.investment.fund
      .getAllInvestmentFunds()
      .then((res) => {
        dispatch(getAllInvestmentFundsAction.done(res.data));
      })
      .catch((error) => dispatch(getAllInvestmentFundsAction.failed(error)));
  };
};

// BUY_FUND_TOKEN

export const buyFundTokenAction = actionCreator(FUND.BUY_FUND_TOKEN);

export const buyFundToken = (data) => {
  return async (dispatch) => {
    dispatch(buyFundTokenAction.started());
    api.investment.fund
      .buyFundToken(data)
      .then((res) => {
        dispatch(buyFundTokenAction.done(res.data));
        dispatch(closeTradeDialog());
        dispatch(getWalletsList({ number: 0, size: 100 }));
        dispatch(getAllInvestmentFunds());
        dispatch(
          openSuccessTradeDialog({
            typeTrade: OPERATION_TYPES.BUY_TOKEN,
            amount: res?.data?.tokenAmount,
          })
        );
      })
      .catch((error) => dispatch(buyFundTokenAction.failed(error)));
  };
};

// SELL_FUND_TOKEN

export const sellFundTokenAction = actionCreator(FUND.SELL_FUND_TOKEN);

export const sellFundToken = (data) => {
  return async (dispatch) => {
    dispatch(sellFundTokenAction.started());
    api.investment.fund
      .sellFundToken(data)
      .then((res) => {
        dispatch(sellFundTokenAction.done(res.data));
        dispatch(closeTradeDialog());
        dispatch(getWalletsList({ number: 0, size: 100 }));
        dispatch(getAllInvestmentFunds());
        dispatch(
          openSuccessTradeDialog({
            typeTrade: OPERATION_TYPES.SELL_TOKEN,
            amount: 0,
          })
        );
      })
      .catch((error) => dispatch(sellFundTokenAction.failed(error)));
  };
};

// GET_USER_FUND_INVOICES

export const getUserFundInvoicesAction = actionCreator(
  FUND.GET_USER_FUND_INVOICES
);

export const getUserFundInvoices = () => {
  return async (dispatch) => {
    dispatch(getUserFundInvoicesAction.started());
    api.investment.fund
      .getUserFundInvoices()
      .then((res) => {
        dispatch(getUserFundInvoicesAction.done(res.data));
      })
      .catch((error) => dispatch(getUserFundInvoicesAction.failed(error)));
  };
};

// GET_USER_FUND_INVOICES_BY_TOKEN

export const getUserFundInvoicesByTokenAction = actionCreator(
  FUND.GET_USER_FUND_INVOICES_BY_TOKEN
);

export const getUserFundInvoicesByToken = () => {
  return async (dispatch) => {
    dispatch(getUserFundInvoicesByTokenAction.started());
    api.investment.fund
      .getUserFundInvoicesByToken()
      .then((res) => {
        dispatch(getUserFundInvoicesByTokenAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserFundInvoicesByTokenAction.failed(error))
      );
  };
};

// GET_USER_FUND_OPERATIONS_HISTORY

export const getUserFundOperationsHistoryAction = actionCreator(
  FUND.GET_USER_FUND_OPERATIONS_HISTORY
);

export const getUserFundOperationsHistory = () => {
  return async (dispatch) => {
    dispatch(getUserFundOperationsHistoryAction.started());
    api.investment.fund
      .getUserFundOperationsHistory()
      .then((res) => {
        dispatch(getUserFundOperationsHistoryAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserFundOperationsHistoryAction.failed(error))
      );
  };
};

// GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN

export const getUserFundOperationsHistoryByTokenAction = actionCreator(
  FUND.GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN
);

export const getUserFundOperationsHistoryByToken = (token, params) => {
  return async (dispatch) => {
    dispatch(getUserFundOperationsHistoryByTokenAction.started());
    api.investment.fund
      .getUserFundOperationsHistoryByToken(token, params)
      .then((res) => {
        dispatch(getUserFundOperationsHistoryByTokenAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserFundOperationsHistoryByTokenAction.failed(error))
      );
  };
};

// GET_USER_FUND_BALANCE

export const getUserFundBalanceAction = actionCreator(
  FUND.GET_USER_FUND_BALANCE
);

export const getUserFundBalance = () => {
  return async (dispatch) => {
    dispatch(getUserFundBalanceAction.started());
    api.investment.fund
      .getUserFundBalance()
      .then((res) => {
        dispatch(getUserFundBalanceAction.done(res.data));
      })
      .catch((error) => dispatch(getUserFundBalanceAction.failed(error)));
  };
};

// GET_USER_FUND_BALANCE_BY_TOKEN

export const getUserFundBalanceByTokenAction = actionCreator(
  FUND.GET_USER_FUND_BALANCE_BY_TOKEN
);

export const getUserFundBalanceByToken = (token) => {
  return async (dispatch) => {
    dispatch(getUserFundBalanceByTokenAction.started());
    api.investment.fund
      .getUserFundBalanceByToken(token)
      .then((res) => {
        dispatch(getUserFundBalanceByTokenAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserFundBalanceByTokenAction.failed(error))
      );
  };
};

// GET_USER_ALL_DIVIDENDS

export const getAllDividendsAction = actionCreator(FUND.GET_USER_ALL_DIVIDENDS);

export const getAllDividends = () => {
  return async (dispatch) => {
    dispatch(getAllDividendsAction.started());
    api.investment.fund
      .getAllDividends()
      .then((res) => {
        dispatch(getAllDividendsAction.done(res.data));
      })
      .catch((error) => dispatch(getAllDividendsAction.failed(error)));
  };
};

// GET_USER_ALL_DIVIDENDS_BY_TOKEN

export const getAllDividendsByTokenAction = actionCreator(
  FUND.GET_USER_ALL_DIVIDENDS_BY_TOKEN
);

export const getAllDividendsByToken = (token) => {
  return async (dispatch) => {
    dispatch(getAllDividendsByTokenAction.started());
    api.investment.fund
      .getAllDividendsByToken(token)
      .then((res) => {
        dispatch(getAllDividendsByTokenAction.done(res.data));
      })
      .catch((error) => dispatch(getAllDividendsByTokenAction.failed(error)));
  };
};

// GET_USER_DIVIDENDS_STATISTIC_BY_FUND

export const getUserDividendsStatisticByFundAction = actionCreator(
  FUND.GET_USER_DIVIDENDS_STATISTIC_BY_FUND
);

export const getUserDividendsStatisticByFund = (token) => {
  return async (dispatch) => {
    dispatch(getUserDividendsStatisticByFundAction.started());
    api.investment.fund
      .getUserDividendsStatisticByFund(token)
      .then((res) => {
        dispatch(getUserDividendsStatisticByFundAction.done(res.data));
      })
      .catch((error) =>
        dispatch(getUserDividendsStatisticByFundAction.failed(error))
      );
  };
};

export const setAmountBuyTokens = (data) => ({
  type: FUND.SET_AMOUNT_BUY_TOKENS,
  payload: data,
});

export const fundsClearStatus = () => ({
  type: FUND.CLEAR_FUND_REQUEST_STATUS,
});

export const fundsClearError = () => ({
  type: FUND.CLEAR_FUND_ERROR,
});
