import React, { useEffect, useCallback, useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageWrapper from "../theme/PageWrapper";
import { SUPPORT_EMAIL } from "../utils/constants/support";
import { useQuery } from "../utils/hooks/useQuery";

export default function Terms() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const query = useQuery();
  const languageFromUrl = query.get("appLanguage");
  const isLanguageFromUrlExist = languageFromUrl && languageFromUrl !== "null";
  const isLanguageSavedInStorage = useMemo(() => {
    const storedLanguage = localStorage.getItem("AppLanguage");
    return storedLanguage && storedLanguage !== "null";
  }, []);

  const handleCheckUrlLang = useCallback(() => {
    if (isLanguageFromUrlExist && isLanguageSavedInStorage) {
      const appLanguage = query.get("appLanguage");
      localStorage.setItem("AppLanguage", appLanguage);
      i18n.changeLanguage(appLanguage);
    }
  }, [i18n, query, isLanguageFromUrlExist, isLanguageSavedInStorage]);

  useEffect(() => {
    handleCheckUrlLang();
  }, [handleCheckUrlLang]);

  return (
    <PageWrapper>
      <Box className={classes.container}>
        <Box className={classes.block}>
          <Typography variant="h2"><b className={classes.highlighted}>{t("TERMS")}</b> {t("OF USE")}</Typography>
          <Typography className={classes.mainDate}>{t("Last updated: April 6, 2020")}</Typography>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.block}>
            <Typography>
              {t(
                "This Terms of use (“Terms”) constitute an Agreement between You and BLOCKSOFTLAB INC, File Number 7176888, registered in the USA, ('Us', 'We', or 'Our') for using Wallet under following terms and conditions."
              )}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "Please read these Terms carefully before using the Wallet.By accessing or using the Wallet You agree to be bound by these Terms, including all Amendments.If you do not agree with these Terms and Privacy Policy, do not access or use the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We reserve our right, at our sole discretion, to change, modify, add or remove portions of these Terms, at any time.It is Your responsibility to check these Terms of Use periodically for changes.Your continued use of the Wallet/of the Website following the posting of changes will mean that you accept and agree to the changes."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "You affirm that you are an individual, at least 18 years of age or older, have the capacity to enter into this Agreement.The Wallet is intended solely for use by legal entity or full-aged individual (18 years).Use of the Wallet by non-adult is prohibited, we are not responsible for any consequences caused by the use of Wallet by non-adult."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>
              <a
                className={classes.link}
                href='https://cryptoneed.com'
                rel='noreferrer'
                target='_blank'>
                https://cryptoneed.com
              </a>{" "}
              {t(
                "('Website') is the website that displays information about the Wallet. Support:"
              )}
              <a
                className={classes.link}
                href={`mailto:${SUPPORT_EMAIL}`}
                rel='noreferrer'
                target='_blank'>
                {SUPPORT_EMAIL}
              </a>
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(
                "Cryptoneed Wallet ('Wallet') is a software program that stores private and public keys (in pairs), provides interactions with various blockchain to enable You to send and receive Virtual currency, perform a transaction with Virtual currency, monitor Yours balance and conduct other operations."
              )}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(
                "'Wallet' covers one or more features provided or operated by Us via mobile application or otherwise, including, but not limited to:"
              )}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t("a) permits You to self-custody Virtual Currency;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "b) implements integration with third parties to allow You to perform Transaction with Virtual Currency and/or Transaction between Virtual Currency and Fiat Money."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(
                "'Virtual Currency' means a cryptocurrency, digital currency, digital asset, crypto assets or other such similar term describing, for example, Bitcoin or Ether."
              )}
            </Typography>
          </Box>

          <Box className={classes.block}>
            <Typography>
              {t(
                "'You' are an individual or a legal entity that uses the Wallet for Your own purposes."
              )}
            </Typography>
          </Box>

          <Box className={classes.block}>
            <Typography>
              {t(
                "'Fiat money' means currency issued by the government, is designated a legal tender on the legislative level in the country of issuance and is suitable for the exchange to each other."
              )}
            </Typography>
          </Box>

          <Box className={classes.block}>
            <Typography>{t(" I. Time of entry into force: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. As soon as You click 'Agree', the Terms will enter into force.By this, You certify that You have read and agreed with these Terms and Privacy Policy as well."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>

          <Box className={classes.block}>
            <Typography>{toString(" II. Legal status: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. We are not an exchange, store, financial or funding institution."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "2. We do not have any access to Your Virtual Currency, bank accounts, bank cards, private keys, passwords, etc."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "3. We do not execute any control over Your transactions or/and Your activity."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "4. We do not endorse or recommend any particular Virtual Currency, transactions, or purchasing strategy.You acknowledge and agree that all transaction decisions are made solely by You and We bear no responsibility or liability for the outcome of Your decisions."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "5. We are not a participant in transactions.All transactions on the Wallet are conducted directly between Users."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "6. The Wallet is not a payment system or an operator of payment transactions."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>

          <Box className={classes.block}>
            <Typography>{t(" III. Amendments: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. We may change, amend, delete or add these Terms at any time and at sole discretion without notice."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "2. Any such changes will be effective upon the posting on the application and/ or on the Website.The effective date which is at the top of the Terms informs You about the latest version of the Terms."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "3. Your continued use of the Wallet constitutes your acceptance of such changes.If You do not agree with any such changes, do not continue to use the Wallet."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" IV. The Wallet: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. When you create a Wallet, the Wallet software generates a cryptographic private and public key pair that You may use to operate any supported Virtual Currency via the relevant Virtual Currency network."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "2. You are solely responsible for the safekeeping mnemonic (seed) phrase associated with your Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "3. You are solely responsible for maintaining the security of your private key."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "4. You must keep your Wallet address, mnemonic (seed) phrase, and private key information secure.Failure to do so may result in the loss of control of Virtual Currency associated with the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "5. If You do not maintain a backup of Your Wallet data/ mnemonic (seed) phrase outside of Yours Wallet, You will be may not be able to access Virtual Currency previously accessed using Your Wallet in the event that We discontinue or no longer offer some or all of the features or may otherwise lose access to Virtual Currency.We are not responsible for maintaining this data on Your behalf."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(" V. The procedure of using the Wallet: ")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t("The Wallet allows You to self-custody Virtual Currency.")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "The Wallet implements integration with third parties to allow You to perform Transaction with Virtual Currency."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "The Wallet implements integration with third parties to allow You to perform Transaction between Virtual Currency and Fiat Money."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "If You wish to make Transaction through the Wallet using Fiat Money, You shall be asked to supply certain information relevant to Your Transaction including, without limitation, Your bank card number, expiration date, and other information."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("You represent and warrant that:")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "1) You have the legal right to use any bank card(s) or another payment method(s) in connection with any Transaction; and"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "2) the information You supply to Us is true, correct and complete."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "3) By submitting such information, You grant Us the right to provide the information to third parties for purposes of fulfillment of Transaction."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "4) Transactions with Virtual Currency and Fiat Money are carried out with the involvement of third parties.We do not bear any responsibility for the actions of such third parties, nor any actions of any other person."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "5) In order to be completed, any Virtual Currency transaction created with the Wallet must be confirmed and recorded in the Virtual Currency ledger associated with the relevant Virtual Currency network.Such networks are decentralized, peer-to-peer networks supported by independent third parties, which are not owned, controlled or operated by Us."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "6) We cannot and do not ensure that any transaction details you submit via our Wallet will be confirmed on the relevant Virtual Currency network.You agree and understand that the transaction details You submit via our Wallet may not be completed, or may be substantially delayed, by the Virtual Currency network used to process the transaction."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "7) Once transaction details have been submitted to a Virtual Currency network, we cannot assist You to cancel or otherwise modify Your transaction or transaction details."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" VI. You are obliged: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t("a) to perform the duties according to this Terms;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "b) to ensure the confidential storage of any information about third parties obtained when using the Wallet, including Personal Data;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "c) not to distribute any information and / or computer programs that contain computer viruses or other dangerous components that are equivalent to them;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "d) not to create a threat to the property, health and safety of countries, people and legal entities."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(
                "d) not to create a threat to the property, health and safety of countries, people and legal entities."
              )}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(
                " VII. We may but are not obliged to provide You with the Wallet. "
              )}
            </Typography>
          </Box>

          <Box className={classes.block}>
            <Typography>
              {t(" VIII. We do not provide any guarantees. ")}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography>
              {t(
                " IX. We do not bear any responsibility, including property liability for the consequences of using the Wallet. "
              )}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" X. Limitation of Liability: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. We have no obligation or capability to verify whether You are eligible to use the Wallet and bears no responsibility for Your use of the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(" 2. We are not responsible for: ")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t("a) correctness of data transmitted by You;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "b) any technical delays, interruptions or lack of availability on the Wallet and the Website;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "c) intermittent failures of the Wallet or the need for repairs, maintenance or the introduction of new facilities, products or services;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("e) any actions of You, third parties;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("f) any consequences of using the Wallet;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("g) any act or omission of You, third parties;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "h) power outages, problems in the transmission of data or connections, access to the Internet, which occurred not through the Us fault;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "i) violation of the rights of third parties as a result of Yours actions committed while using the Wallet."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    " 3. We are not liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, damage for loss of profits, data, use, goodwill, business interruption or any other commercial damages, or other intangible losses, resulting from: "
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "your access to or use of or inability to access or use the Wallet;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "any conduct or content of any third party on the Wallet;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("any content obtained from the Wallet; and")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "unauthorized access, use or alteration of Your transmissions or content, whether based on warranty, contract, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "4. You will be complying with and obeying all applicable laws, including but not limited to securities and capital market legislation, anti-money laundering and counterfeiting terrorism, consumer protection laws, financial promotion.We are not responsible for the above-mentioned violations of the law by You."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "5. In no event shall Our total liability to You under using Wallet for all damages exceed the amount of fifty dollars ($50.00)."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XI. Disclaimer: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t("Your use of the Wallet at Your sole risk.")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "You agree that the entire risk as to the satisfactory quality, performance, accuracy, and effort is with You."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "The Wallet is provided on an 'AS IS' and 'AS AVAILABLE' basis."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "The Wallet is provided with all faults and without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, accuracy, timeliness, non-infringement of third parties right or course of performance."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We do not warrant against interference with Your enjoyment of the Wallet, that the Wallet will meet Your requirements, that the operation of the Wallet will be uninterrupted, timely, secure or error-free."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We do not warrant that defects or errors in the Wallet will be corrected or that any information stored or transmitted through the Wallet will not be lost, corrupted or damaged."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "You acknowledge that the Wallet is not intended or suitable for use in situations or environments where errors, delays, failures or inaccuracies in the transmission or storage of data or information by or through the Wallet lead to death, personal injury, or financial, physical, property or environmental damage."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "No oral or written information or advice is given by Us will create a warranty not expressly stated in this Terms."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Should the Wallet prove defective, you assume the entire cost of all necessary servicing, repair or correction."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We may extend, enhance, or otherwise modify the Wallet (or any part thereof) provided hereunder at any time without notice, but We shall not be obligated to provide You with any Updates to the Wallet.You undertake to download such update and/ or You are responsible for the absence of downloading update on Your device."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We are not responsible for any loss or damages of your data, currency, keys, etc.that may be caused by such an update."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "You understand that such modifications may require You to change or update Your Application.Further, You acknowledge and agree that such modifications may affect Your ability to use, access, or interact with the Wallet."
                  )}
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  {t(
                    "We are not obligated to provide any maintenance, technical or other support for the Wallet."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XIІ. Your responsibility: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. You must not otherwise reproduce, adapt, store, transmit, distribute, print, display, commercialize, publish or create derivative works from any part of the content, format or design of the Website."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "2. You are fully responsible for the Know Your Customer (KYC) policy and source of funds."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>{t(" 3. You are responsible for: ")}</ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "complying with the law of the country where they reside;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "keeping Your passwords confidential.You bear responsibility for a loss of Yours passwords and may be held liable for any losses arising out of such failure;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "implementation of actions that could pose a threat to the normal functioning of the Wallet and /or the Website;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t("any harm caused by Your actions.")}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XIІI. Sanctions policy: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. You shall not use the Wallet if you are a resident or have a place of registration in the countries that are in the sanctioned territory list such as Cuba, Iran, North Korea, Sudan, the Crimea Region of Ukraine and Syria."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XІV. Privacy Policy: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. You agree to the Privacy Policy which explains how we collect, uses, process and protect the Personal Data You provide to Us when You use the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("2. The Privacy Policy is a part of these Terms.")}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XV. Intellectual Property Ownership: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. All text, graphics, patents, applications, editorial content, data, formatting, graphs, designs, HTML, images, software, and other content of the API, Website, etc (collectively 'Proprietary Material') that you see or read through the Website is owned by Us."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("2. Proprietary Material is protected.")}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XVI. Termination: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "1. The agreement between You and Us is valid as long as You use the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "2. Your relationship with Us and use of the Wallet may be subject to the laws, regulations, and rules of governmental or regulatory authorities in your or our jurisdiction ('Applicable Law').By entering into this agreement, you agree to act in compliance with and be legally bound to any and all Applicable Law."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography>{t(" XVII. Miscellaneous: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "We reserve our right to change, modify, supplement and extend the features of the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We reserve the right, in our sole discretion, to carry out identification/verification procedures, to require you to confirm the origin of the assets, information about the nature of the financial transaction, as well as any other information at our discretion.By using Your Wallet You agree to provide the necessary documents and information."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We reserve the right to freeze assets in case there is a suspicion of money laundering obtained illegally.By using the Wallet You agree to provide documents and information that confirm the legality of their origin."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "We reserve the right to implement additional services, referral programs and other features related to the use of the Wallet.Such changes and/or innovations will be reflected in these Terms and/or on the Website and/or within an application and/or in separate documents that form an integral part of these Terms and establish the rules for their use."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 auto",
    color: "#ffffff",
    marginTop: '40px',
  },
  content: {
    background: '#212328',
    padding: '32px',
    borderRadius: '20px',
  },
  mainDate: {
    paddingLeft: '20px',
    color: theme.palette.text.quaternary,
    marginTop: '10px',
    marginBottom: '20px'
  },
  highlighted: {
    color: theme.palette.text.tertiary,
  },
  block: {
    marginBottom: "24px",
    color: "#ffffff",
    "& li": {
      textAlign: "justify",
      paddingLeft: 24,
      paddingRight: 0,
    },
  },
  link: {
    color: theme.palette.action.active,
    fontWeight: "600",
  },
}));
