import { CLOSE_DIALOG, OPEN_DIALOG } from "../types";

export default function dialogs(
  state = {
    trade: {
      typeTrade: "",
      item: null,
      settings: null,
      userBalanceAsset: 0,
      isIndexes: false,
    },
    info: {
      open: false,
    },
    successTrade: {
      typeTrade: "",
      amount: 0,
    },
  },
  action
) {
  switch (action.type) {
    case OPEN_DIALOG.INFO:
      return { ...state, info: { open: true } };
    case CLOSE_DIALOG.INFO:
      return { ...state, info: { open: false } };
    case OPEN_DIALOG.TRADE:
      return { ...state, trade: action.payload };
    case CLOSE_DIALOG.TRADE:
      return {
        ...state,
        trade: {
          typeTrade: "",
          item: null,
          settings: null,
          userBalanceAsset: 0,
          isIndexes: false,
        },
      };
    case OPEN_DIALOG.SUCCESS_TRADE:
      return {
        ...state,
        successTrade: action.payload,
      };
    case CLOSE_DIALOG.SUCCESS_TRADE:
      return { ...state, successTrade: { typeTrade: "", amount: 0 } };
    default:
      return state;
  }
}
