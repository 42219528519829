import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, withStyles, Slide, Typography, Grid } from "@material-ui/core";
import { Button } from "../../../theme/components/Button";
import { OPERATION_TYPES } from "../../../utils/constants/capital";
import tradeSuccess from "../../../assets/images/background_trade.svg";
import { closeSuccessTradeDialog } from "../../../redux/actions/dialogActions";
import { roundValue } from "../../../utils/functions/roundValue";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  dialog: {
    width: 285,
    height: 307,
    backgroundImage: `url(${tradeSuccess})`,
    backgroundRepeat: "no-repeat",
    borderRadius: 20,
  },
  button: { maxHeight: 36, maxWidth: 117, minWidth: 100, fontSize: 10 },
};

const TradeSuccessDialog = ({ classes }) => {
  const dispatch = useDispatch();
  const { typeTrade, amount } = useSelector(
    ({ dialogs }) => dialogs.successTrade
  );

  const handleClose = () => {
    dispatch(closeSuccessTradeDialog());
  };

  return (
    <Dialog
      open={Boolean(typeTrade)}
      TransitionComponent={Transition}
      fullWidth={false}
      keepMounted
      maxWidth={false}
      classes={{ paper: classes.dialog }}
      onClose={handleClose}
    >
      <Grid
        container
        style={{ padding: "74px 24px 24px", height: "100%" }}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant={"h3"} align="center">
            {typeTrade === OPERATION_TYPES.SELL_TOKEN
              ? "Success!"
              : "Congratulations!"}
          </Typography>
        </Grid>
        <Grid item>
          {typeTrade === OPERATION_TYPES.SELL_TOKEN ? (
            <Typography variant={"body1"} align="center" color="textSecondary">
              {
                "Your application has been sent and will be processed within 24 hours"
              }
            </Typography>
          ) : (
            amount > 0 && (
              <>
                <Typography
                  variant={"body1"}
                  align="center"
                  color="textPrimary"
                >
                  {roundValue(amount, 2)} {" tokens credited"}
                </Typography>
                <Typography
                  variant={"body1"}
                  align="center"
                  color="textSecondary"
                >
                  {"to your cryptoneed balance"}
                </Typography>
              </>
            )
          )}
        </Grid>
        <Grid item>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            onClick={handleClose}
          >
            {"OK"}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles)(TradeSuccessDialog);
