import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "./apiMiddleware";
import loadingMiddleware from "./loadingMiddleware";
import loading from "../reducers/loadingReducer";
import dialogs from "../reducers/dialogsReducer";
import account from "../reducers/accountReducer";
import notistack from "../reducers/notistackReducer";
import wallets from "../reducers/walletsReducer";
import indexes from "../reducers/indexesReducer";
import funds from "../reducers/fundsReducer";
import language from "../reducers/languageReducer";

const combinedReducer = combineReducers({
  notistack,
  loading,
  dialogs,
  account,
  wallets,
  indexes,
  funds,
  language,
});

const rootReducer = (state, action) => {
  // if (action.type === "LOG_OUT" || action.type === "BACK_TO_MAIN") {
  //   state = undefined;
  // }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddleware, loadingMiddleware),
});
