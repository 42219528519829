import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  // lighten,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { sellFundToken } from "../../../../redux/actions/fundActions";
import { getWalletsList } from "../../../../redux/actions/walletsActions";
import clsx from "clsx";
import {
  StyledSlider,
  ThumbComponent,
} from "../../../../theme/components/StyledSlider";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import DeleteConfirmDialog from "../../../../theme/dialogs/DeleteConfirmDialog";
import { MARKS_SLIDER } from "../../../../utils/constants/capital";
import { roundValue } from "../../../../utils/functions/roundValue";
import { getTicker } from "../../../../utils/functions/getTicker";
import Attention from "../../../../assets/icons/attention_outlined.svg";
import { sellIndexToken } from "../../../../redux/actions/indexesActions";

const Sale = ({ item, settings, isIndexes }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [amountToken, setAmountToken] = useState(0);
  const [amount, setAmount] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const price = useMemo(
    () => (isIndexes ? item?.sellPrice : item?.price),
    [isIndexes, item]
  );

  const handleSliderChange = (event, newValue) => {
    if (item?.balance === 0) {
      setValue(0);
      setAmountToken(0);
      setAmount(0);
      return;
    }
    setValue(newValue);
    const valuePercent = 100 / item?.balance;
    setAmountToken(Math.trunc(newValue / valuePercent));
    setAmount(Math.trunc(newValue / valuePercent) * price);
  };

  const handleChangeAmount = (value) => {
    setAmountToken(value);
    setValue(value * (100 / item?.balance));
    setAmount(value * price);
  };

  const handleConfirm = ({ accept }) => {
    if (accept && amount > 0) {
      dispatch(
        isIndexes
          ? sellIndexToken({ indexId: item.id, amount: amountToken })
          : sellFundToken({
            fundId: item?.id,
            amount: amountToken,
          })
      ).then((response) => {
        response &&
          dispatch(dispatch(getWalletsList({ number: 0, size: 100 })));
      });
    }
    setAmount(0);
    setAmountToken(0);
    setValue(0);
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <DeleteConfirmDialog
        open={openConfirmDialog}
        title="Sell tokens"
        message={`Are you sure you want to sell ${amountToken} tokens ?`}
        handleClose={handleConfirm}
        btnOkText="Confirm"
        confirm={true}
      />

      <Paper elevation={4} className={classes.root}>
        <Grid container className={classes.head}>
          <Typography variant={"h4"} className={classes.title}>
            Sell
          </Typography>
        </Grid>

        <Grid container className={classes.contentBox}>
          <Grid item xs={12} className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Input
                  className={classes.input}
                  tabIndex="0"
                  variant="outlined"
                  label={"I want sell"}
                  name={"input"}
                  value={amountToken}
                  autoComplete="off"
                  onChange={({ target: { value } }) =>
                    /^[0-9]*$/.test(value) &&
                    value <= Math.trunc(item?.balance) &&
                    !(
                      value.length > 1 &&
                      value[0] === "0" &&
                      value[1] !== "."
                    ) &&
                    handleChangeAmount(value)
                  }
                  onFocus={() => !amountToken && setAmountToken("")}
                  onBlur={() => !amountToken && setAmountToken(0)}

                // error={errors?.username?.invalid}
                // helperText={t(errors?.username?.errorMessage) || ""}
                />
              </Grid>
              <Grid  container item xs={5} direction="column" alignItems="flex-start" justifyContent="space-around">
                <div className={clsx(classes.labelText)}>
                  {"Preliminary exchange rate"}
                </div>
                <Typography className={clsx(classes.subtitleBox)}>
                <span className={classes.numberText}>{roundValue(amount, settings?.minScale)}{" "}</span>
                  {getTicker(item?.asset)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.sliderContainer}>
              <StyledSlider
                ThumbComponent={ThumbComponent}
                aria-labelledby="track-false-slider"
                value={typeof value === "number" ? value : 0}
                onChange={handleSliderChange}
                marks={MARKS_SLIDER}
                step={100 / item?.balance}
              />
            </Grid>
          </Grid>

          <Paper className={classes.attention}>
            <Grid container>
              <Grid item xs={1} style={{ position: "relative" }}>
                <img
                  src={Attention}
                  alt="token"
                  className={classes.attentionImg}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.attentionText}>
                  {"Application will be processed within 24 hours"}
                </Typography>
                <Typography className={classes.attentionText}>
                  {
                    "After confirming the application, USDT will be credited to your wallet Cryptoneed"
                  }
                </Typography>
                <Typography className={classes.attentionText}>
                  {
                    "At the time of confirmation of the application, the exchange rate may change"
                  }
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Grid item container justifyContent="flex-end">
            <Button
              className={classes.button}
              variant="contained"
              disabled={!+amountToken}
              onClick={() => setOpenConfirmDialog(true)}
            >
              {"SALE REQUEST"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Sale;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: '32px 24px',
    borderRadius: 12,
    background: `${theme.palette.background.paper} !important`,
  },
  tokenBox: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    border: "1px solid rgba(198, 198, 197, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 6,
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.text.quaternary,
    marginBottom: 20,
  },
  subtitleBox: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "21px",
    fontStyle: "normal",
    textTransform: "none",
  },
  labelText: {
    fontSize: 12,
  },
  numberText: {
    fontSize: 24,
  },
  sliderContainer: {
    marginTop: 16,
    marginBottom: 16,
  },
  contentBox: {
  },
  section: {
    marginBottom: 12,
  },
  textBox: {
    display: "flex",
    alignItems: "center",
  },
  textBox_title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  textBox_img: {
    marginLeft: 6,
  },
  paper: {
    background: theme.palette.background.header,
  },
  caption: {
    color: "#000000",
    fontSize: 6,
    fontWeight: 400,
    lineHeight: "7px",
  },
  input: {
  },
  span: {
    fontSize: 9,
    fontWeight: 400,
    lineHeight: 11,
  },
  white: {
    color: "#FFFFFF",
  },

  attention: {
    width: "100%",
    borderRadius: 18,
    border: `1px solid #484E5A`,
    background: '2B2F38',
    padding: 8,
    paddingLeft: 16,
    marginBottom: 20
  },
  attentionText: {
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: 300,
    lineHeight: "14px",
  },
  attentionImg: {
    width: 17,
    height: 15,
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
  },
  button: {
    maxWidth: 120,
    alignSelf: 'flex-end',
    "&.MuiButton-contained ": {
      maxHeight: 36,
      minWidth: 140,
      fontSize: 10,
      padding: 0,
      borderColor: theme.palette.text.quaternary,
    },
    "& .MuiButton-label": {
    },
  },
  token: {
    width: 16,
    height: 16,
    fontSize: 6,
    color: theme.palette.secondary.main,
    borderRadius: "50%",
    fontWeight: 800,
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 6,
    // marginTop: 2,
  },
  LOW: {
    backgroundColor: "rgba(244, 229, 88, 1)",
  },
  MEDIUM: {
    backgroundColor: "rgba(248, 177, 51, 1)",
  },
  HIGH: {
    backgroundColor: "rgba(233, 90, 12, 1)",
  },
}));
