import { Typography, Paper, makeStyles, Grid } from "@material-ui/core";
import { useCallback } from "react";
import { CapitalTabs } from ".";
import clsx from "clsx";
import Risk from "../../theme/components/Risk";

const SwitcherBlock = ({ onTabChange, activeTab }) => {
  const classes = useStyles();

  const handleTabChange = useCallback(() => {
    if (activeTab === CapitalTabs.fund) {
      onTabChange(CapitalTabs.index)
    } else {
      onTabChange(CapitalTabs.fund)
    }
  }, [activeTab, onTabChange])

  return (
    <Paper className={classes.root}>
      <div className={classes.switcher}>
        <div onClick={handleTabChange} className={clsx(classes.tab, activeTab === CapitalTabs.fund ? classes.tabActive : {})}>
          <Typography variant="body2" className={clsx(classes.tabText, activeTab === CapitalTabs.fund ? classes.tabTextActive : {})}>
            Funds
          </Typography>
        </div>
        <div onClick={handleTabChange} className={clsx(classes.tab, activeTab === CapitalTabs.index ? classes.tabActive : {})}>
          <Typography variant="body2" className={clsx(classes.tabText, activeTab === CapitalTabs.index ? classes.tabTextActive : {})}>
            Index
          </Typography>
        </div>
      </div>
      {
        activeTab === CapitalTabs.fund ?
          <Typography
            variant={"body1"}
            className={classes.description}
          >
            A crypto fund is a type of investment fund that focuses on
            cryptocurrencies and digital assets. It is designed to provide investors with exposure to the rapidly growing and volatile world of cryptocurrencies. These funds are managed by experienced professionals who actively trade, invest, and manage a diversified portfolio of digital assets on behalf of the fund's investors.
          </Typography> :

          <Typography
            variant={"body1"}
            className={classes.description}
          >
            Crypto indices are statistical measures that track the performance of a specific segment or a basket of cryptocurrencies. They provide investors with a comprehensive overview of the cryptocurrency market as a whole or specific subsets of the market.
          </Typography>
      }
      <Grid direction="row" wrap="nowrap" container alignItems="flex-end">
        <Risk size={64} />
        <Typography className={classes.riskText}>
          Risk Level
        </Typography>
      </Grid>
    </Paper >
  );
};

export default SwitcherBlock;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  title: {
    marginBottom: 24,
    marginLeft: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginBottom: 12,
      marginLeft: 0,
    },
  },
  description: {
    fontSize: 15,
    marginTop: 24,
    marginBottom: 40,
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    height: '56px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '19px',
    padding: '5px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.dark
  },
  tab: {
    width: '90%',
    height: '100%',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabActive: {
    backgroundColor: theme.palette.primary.light,
    width: '110%',
  },
  tabText: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tabTextActive: {
    fontSize: 18,
    color: theme.palette.text.quaternary
  },
  riskText: {
    color: theme.palette.text.quaternary,
    fontSize: 24,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginLeft: 10
  }
}));
