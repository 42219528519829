import moment from "moment";
import { INDEX } from "../types";

export default function indexes(
  state = {
    indexesList: [],
    estimateBuyToken: null,
    estimateSellToken: null,
    swapToken: null,
    operationsHistory: null,
    userIndexBalance: null,
    userIndexInvoices: null,
    status: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case INDEX.GET_ALL_INVESTMENT_INDEX.REQUEST:
    case INDEX.ESTIMATE_BUY_INDEX_TOKEN.REQUEST:
    case INDEX.BUY_INDEX_TOKEN.REQUEST:
    case INDEX.ESTIMATE_SELL_INDEX_TOKEN.REQUEST:
    case INDEX.SELL_INDEX_TOKEN.REQUEST:
    case INDEX.SWAP_INDEX_TOKEN.REQUEST:
    case INDEX.GET_USER_INDEX_BALANCE.REQUEST:
    case INDEX.GET_USER_INDEX_BALANCE_BY_TOKEN.REQUEST:
    case INDEX.GET_USER_INDEX_OPERATIONS_HISTORY.REQUEST:
    case INDEX.GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN.REQUEST:
    case INDEX.GET_USER_INDEX_INVOICES.REQUEST:
    case INDEX.GET_USER_INDEX_INVOICES_BY_TOKEN.REQUEST:
      return { ...state, error: null };

    case INDEX.GET_ALL_INVESTMENT_INDEX.SUCCESS:
      return { ...state, indexesList: action.payload.content };
    case INDEX.ESTIMATE_BUY_INDEX_TOKEN.SUCCESS:
      return { ...state, estimateBuyToken: action.payload };
    // case INDEX.BUY_INDEX_TOKEN.SUCCESS:
    case INDEX.ESTIMATE_SELL_INDEX_TOKEN.SUCCESS:
      return { ...state, estimateSellToken: action.payload };
    // case INDEX.SELL_INDEX_TOKEN.SUCCESS:
    case INDEX.SWAP_INDEX_TOKEN.SUCCESS:
      return { ...state, swapToken: action.payload };
    case INDEX.GET_USER_INDEX_BALANCE.SUCCESS:
    case INDEX.GET_USER_INDEX_BALANCE_BY_TOKEN.SUCCESS:
      return { ...state, userIndexBalance: action.payload };
    case INDEX.GET_USER_INDEX_OPERATIONS_HISTORY.SUCCESS:
    case INDEX.GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN.SUCCESS:
      return {
        ...state,
        operationsHistory: {
          ...action.payload,
          content: action.payload.content.sort((a, b) =>
            moment.utc(b.time).diff(moment.utc(a.time))
          ),
        },
      };
    case INDEX.GET_USER_INDEX_INVOICES.SUCCESS:
    case INDEX.GET_USER_INDEX_INVOICES_BY_TOKEN.SUCCESS:
      return { ...state, userIndexInvoices: action.payload };
    case INDEX.GET_ALL_INVESTMENT_INDEX.FAILURE:
    case INDEX.ESTIMATE_BUY_INDEX_TOKEN.FAILURE:
    case INDEX.BUY_INDEX_TOKEN.FAILURE:
    case INDEX.ESTIMATE_SELL_INDEX_TOKEN.FAILURE:
    case INDEX.SELL_INDEX_TOKEN.FAILURE:
    case INDEX.SWAP_INDEX_TOKEN.FAILURE:
    case INDEX.GET_USER_INDEX_BALANCE.FAILURE:
    case INDEX.GET_USER_INDEX_BALANCE_BY_TOKEN.FAILURE:
    case INDEX.GET_USER_INDEX_OPERATIONS_HISTORY.FAILURE:
    case INDEX.GET_USER_INDEX_OPERATIONS_HISTORY_BY_TOKEN.FAILURE:
    case INDEX.GET_USER_INDEX_INVOICES.FAILURE:
    case INDEX.GET_USER_INDEX_INVOICES_BY_TOKEN.FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case INDEX.CLEAR_INDEX_REQUEST_STATUS:
      return {
        ...state,
        status: false,
        error: null,
      };
    case INDEX.CLEAR_INDEX_ERROR:
      return {
        ...state,
        status: false,
        error: null,
      };
    default:
      return state;
  }
}
