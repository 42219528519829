import { forwardRef } from "react";
import {
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Typography,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../assets/icons/close.svg";
import ArrowBackIcon from "../../assets/icons/back.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DialogWrapper = ({ open, handleClose, children, handleBack, title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      keepMounted
      onClose={handleClose}>
      <Paper elevation={4} className={classes.root}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          wrap='nowrap'
          className={classes.header}>
          <Grid item>
            {Boolean(handleBack) && (
              <IconButton size={"small"} onClick={handleBack}>
                <img src={ArrowBackIcon} alt='backIcon' />
              </IconButton>
            )}
          </Grid>

          <Grid
            item
            container
            justifyContent='center'
            className={classes.title}>
            <div></div>
            <Typography variant={"h4"} color={"textPrimary"}>
              {t(title)}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size={"small"} onClick={handleClose}>
              <img src={CloseIcon} alt='close' />
            </IconButton>
          </Grid>
        </Grid>

        <div className={classes.box}>{children}</div>
      </Paper>
    </Dialog>
  );
};

DialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default DialogWrapper;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    height: 64,
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {},
  },
  title: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  box: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
}));
