import DialogWrapper from "./DialogWrapper";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

const DeleteConfirmDialog = ({
  open,
  title,
  message,
  handleClose,
  btnOkText = "Ok",
  btnCancelText = "Cancel",
  confirm,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}
      title={title}>
      <Typography
        variant={"body1"}
        align={"left"}
        color={"textPrimary"}
        style={{ marginBottom: 24 }}>
        {t(message)}
      </Typography>
      <Grid container justifyContent='space-between'>
        <Button
          style={{
            minWidth: isMobile ? "100%" : "max(25%, 50px)",
            marginTop: 12,
            backgroundColor: confirm ? "#FF2F51" : "#49AB5B",
          }}
          onClick={() => handleClose({ accept: false })}>
          {t(btnCancelText)}
        </Button>
        <Button
          style={{
            minWidth: isMobile ? "100%" : "max(25%, 50px)",
            marginTop: 12,
            backgroundColor: confirm ? "#49AB5B" : "#FF2F51",
          }}
          onClick={() => handleClose({ accept: true })}>
          {t(btnOkText)}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default DeleteConfirmDialog;
