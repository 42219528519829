import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

export const StyledSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
    marginTop: 8,
  },

  mark: {
    height: 6,
    width: 3,
    backgroundColor: "rgba(70, 75, 88, 1)",
    marginTop: "-3px",
  },
  markActive: { backgroundColor: '#4AAF57' },
  markLabel: {
    fontSize: 9,
    fontWeight: 400,
    lineHeight: "10px",
    color: '#fff',

    "&[data-index='0']": {
      left: "3% !important",
    },
    "&[data-index='4']": {
      left: "96% !important",
    },
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: "#fff",
    marginTop: -7,
    marginLeft: -4,
    borderRadius: 8,
    "& .bar": {
      height: 7,
      width: 1,
      backgroundColor: "rgba(34, 41, 48, 1)",
      marginLeft: 1.5,
      marginRight: 1.5,
    },
  },
  active: {},
  track: {
    height: 3,
    backgroundColor: '#4AAF57',
  },
  rail: {
    color: "rgba(70, 75, 88, 1)",
    opacity: 1,
    height: 3,
  },
})(Slider);

export function ThumbComponent(props) {
  return (
    <span {...props}>
      <span className='bar' />
      <span className='bar' />
      <span className='bar' />
    </span>
  );
}
