import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const BurgerIcon = ({ onChange }) => {
  const classes = useStyles();
  const [isHumbergerClicked, setIsHamburgerClicked] = useState(false);

  const clickHandler = () => {
    setIsHamburgerClicked(!isHumbergerClicked);
    onChange(!isHumbergerClicked);
  };

  return (
    <button
      className={clsx(
        classes.menuButton,
        isHumbergerClicked ? classes.active : ""
      )}
      aria-label="Open menu"
      onClick={clickHandler}
    >
      <span className={classes.bar}></span>
      <span className={classes.bar}></span>
      <span className={classes.bar}></span>
    </button>
  );
};

export default BurgerIcon;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    display: "block",
    transformOrigin: "16px 11px",
    float: "left",
    outline: "none",
    border: 0,
    padding: "4px",
    background: "none",
    transform: "scale(0.9)",
    cursor: "pointer",
    "& span": {
      transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
    },

    "&:focus": {
      border: "medium none rgb(111, 255, 176)",
      outline: "none",
    },

    "&:hover span:nth-of-type(1)": {
      width: "38px",
    },

    "&:hover span:nth-of-type(2)": {
      width: "40px",
    },

    "&:hover  span:nth-of-type(3)": {
      width: "37px",
    },
  },
  active: {
    "& span:nth-of-type(1)": {
      transform: "rotate(45deg) translate(9px, 9px)",
      width: "40px",
    },

    "& span:nth-of-type(2)": {
      opacity: 0,
      pointerEvents: "none",
    },

    "& span:nth-of-type(3)": {
      transform: "rotate(-45deg) translate(6px, -5px)",
      width: "40px",
    },
  },

  bar: {
    display: "block",
    width: "40px",
    height: "3px",
    marginBottom: "7px",
    backgroundColor: " #fff",
  },
}));
