import { useEffect, useState, useMemo } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PageWrapper from "../../../../theme/PageWrapper";
import Breadcrumb from "../../../../theme/components/Breadcrumb";
import History from "../../Funds/FundDetails/History";
import Invest from "../../Funds/FundDetails/Invest";
import Sale from "../../Funds/FundDetails/Sale";
import {
  getAllTickerSettings,
  getWalletsList,
} from "../../../../redux/actions/walletsActions";
import { roundValue } from "../../../../utils/functions/roundValue";
import { getTicker } from "../../../../utils/functions/getTicker";
import { useStylesCardDetails } from "../../Funds/FundDetails";
import {
  getAllInvestmentIndex,
  getUserIndexOperationsHistoryByToken,
} from "../../../../redux/actions/indexesActions";
import Risk from "../../../../theme/components/Risk";

const IndexDetails = () => {
  const classes = useStylesCardDetails();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [item, setItem] = useState(null);
  const { indexesList, operationsHistory } = useSelector(
    ({ indexes }) => indexes
  );
  const { data, allTickerSettings } = useSelector(({ wallets }) => wallets);

  useEffect(() => {
    dispatch(getAllInvestmentIndex());
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getAllTickerSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!item) {
      return;
    }
    dispatch(
      getUserIndexOperationsHistoryByToken(item?.token, {
        size: 10,
        page: page,
      })
    );
  }, [dispatch, item, page]);

  useEffect(() => {
    if (!id || !indexesList) {
      return;
    }
    setItem(
      indexesList?.find((i) => {
        return +i.id === +id;
      })
    );
  }, [dispatch, id, indexesList]);

  const indexUserWallet = useMemo(() => {
    return data?.find((i) => i.ticker === (item?.asset || "usdt-trc20"));
  }, [data, item]);

  const walletSettings = useMemo(() => {
    return allTickerSettings?.find(
      (i) => i.ticker === (item?.asset || "usdt-trc20")
    );
  }, [allTickerSettings, item]);

  return (
    <PageWrapper>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} className={classes.root}>
          <Grid container justifyContent="flex-start" alignItems="center" className={classes.header}>
            <Breadcrumb
              to={{ path: "/", state: { tab: 1 } }}
              pageName={"Capital"}
              down={0}
            />
            <Grid
              xs={12}
              item
              container
              className={classes.head}
            >
              <Grid item xs={12} container direction="row" className={classes.titleBox}>
                <Typography variant={"h2"} className={classes.title}>
                  {item?.token}
                </Typography>
                <Typography variant={"h4"} className={classes.name}>
                  {item?.name.slice(0, 6)}
                </Typography>
                <Risk risk={item?.riskLevel} size={36} />
              </Grid>
            </Grid>
          </Grid>
          <Paper className={classes.infoBlock} style={{ justifyContent: 'flex-end', gap: '5%' }}>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Token balance
                <span className={classes.infoBlockPrefix}>{roundValue(item?.balance * item?.buyPrice, 2)} USDT</span>
              </Typography>
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant={"h3"} className={classes.infoBlockValue}>
                  <span className={classes.infoBlockNumber}>{item?.balance}</span>
                  <span className={clsx(
                    classes.tokenIcon,
                  )}>{item?.token.slice(0, 3)}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Coin Price
              </Typography>
              <Typography variant={"h3"} className={classes.infoBlockValue}>
                <span className={classes.infoBlockNumber}>{item?.buyPrice}{" "}</span>
                {getTicker(item?.asset || "usdt-trc20")}
              </Typography>
            </Grid>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Wallet balance
              </Typography>
              <Typography variant={"h3"} className={classes.infoBlockValue}>
                <span className={classes.infoBlockNumber}>{roundValue(
                  indexUserWallet?.balance,
                  walletSettings?.minScale
                )}{" "}</span>
                {getTicker(item?.asset || "usdt-trc20")}
              </Typography>
            </Grid>
          </Paper>
          <Grid>
            <Grid
              container
              spacing={4}
              style={{ paddingTop: 32, paddingBottom: 32 }}
            >
              <Grid item xs={12} md={7}>
                <History
                  isIndex={true}
                  history={operationsHistory}
                  page={page}
                  changePage={(page) => setPage(page)}
                  settings={walletSettings}
                />
              </Grid>
              <Grid item xs={12} md={5} className={classes.tradeBox}>
                <Invest
                  balance={indexUserWallet?.balance}
                  item={item}
                  settings={walletSettings}
                  isIndexes={true}
                />
                <Sale item={item} settings={walletSettings} isIndexes={true} />
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.detailsBlock}>
                  <Grid container>
                    <Grid item >
                      <Typography
                        variant={"h4"}
                        className={classes.blockTitle}
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid item className={classes.description}>
                      <Typography
                        variant={"body2"}
                        color="textSecondary"
                        align="justify"
                        noWrap={false}
                      >
                        {item?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default IndexDetails;
