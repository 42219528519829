import moment from "moment";
import { FUND } from "../types";

export default function funds(
  state = {
    fundList: null,
    operationsHistory: null,
    userFundBalance: null,
    userFundDividends: null,
    userFundDividendsStatistic: [],
    amountBuyTokens: 0,
    error: null,
    status: false,
  },
  action
) {
  switch (action.type) {
    case FUND.GET_ALL_INVESTMENT_FUNDS.REQUEST:
    case FUND.GET_USER_FUND_OPERATIONS_HISTORY.REQUEST:
    case FUND.GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN.REQUEST:
    case FUND.GET_USER_FUND_BALANCE.REQUEST:
    case FUND.GET_USER_FUND_BALANCE_BY_TOKEN.REQUEST:
    case FUND.GET_USER_ALL_DIVIDENDS.REQUEST:
    case FUND.GET_USER_ALL_DIVIDENDS_BY_TOKEN.REQUEST:
    case FUND.GET_USER_DIVIDENDS_STATISTIC_BY_FUND.REQUEST:
      return { ...state, error: null };

    case FUND.GET_ALL_INVESTMENT_FUNDS.SUCCESS:
      return { ...state, fundList: action.payload.content };

    case FUND.GET_USER_FUND_OPERATIONS_HISTORY.SUCCESS:
    case FUND.GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN.SUCCESS:
      return {
        ...state,
        operationsHistory: {
          ...action.payload,
          content: action.payload.content.sort((a, b) =>
            moment.utc(b.time).diff(moment.utc(a.time))
          ),
        },
      };
    case FUND.GET_USER_FUND_BALANCE.SUCCESS:
    case FUND.GET_USER_FUND_BALANCE_BY_TOKEN.SUCCESS:
      return { ...state, userFundBalance: action.payload };

    case FUND.GET_USER_ALL_DIVIDENDS.SUCCESS:
    case FUND.GET_USER_ALL_DIVIDENDS_BY_TOKEN.SUCCESS:
      return { ...state, userFundDividends: action.payload };

    case FUND.GET_USER_DIVIDENDS_STATISTIC_BY_FUND.SUCCESS:
      let fundList = state.fundList;
      if (fundList?.find((i) => i.token === action.payload?.token)) {
        Object.assign(
          fundList?.find((i) => i.token === action.payload?.token),
          action.payload
        );
      } else {
        fundList.push(action.payload);
      }
      return {
        ...state,
        fundList,
        userFundDividendsStatistic: action.payload,
      };
    case FUND.SET_AMOUNT_BUY_TOKENS:
      return { ...state, amountBuyTokens: action.payload };

    case FUND.CLEAR_REQUEST_STATUS:
      return { ...state, status: false };

    // case FUND.BUY_FUND_TOKEN.SUCCESS:
    // case FUND.SELL_FUND_TOKEN.SUCCESS:
    //   return { ...state, status: true };

    case FUND.GET_ALL_INVESTMENT_FUNDS.FAILURE:
    case FUND.GET_USER_FUND_OPERATIONS_HISTORY.FAILURE:
    case FUND.GET_USER_FUND_OPERATIONS_HISTORY_BY_TOKEN.FAILURE:
    case FUND.GET_USER_FUND_BALANCE.FAILURE:
    case FUND.GET_USER_FUND_BALANCE_BY_TOKEN.FAILURE:
    case FUND.GET_USER_ALL_DIVIDENDS.FAILURE:
    case FUND.GET_USER_ALL_DIVIDENDS_BY_TOKEN.FAILURE:
    case FUND.GET_USER_DIVIDENDS_STATISTIC_BY_FUND.FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
