import React, { useEffect, useCallback, useMemo } from "react";
import PageWrapper from "../theme/PageWrapper";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { SUPPORT_EMAIL } from "../utils/constants/support";
import { useQuery } from "../utils/hooks/useQuery";

export default function Privacy() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const query = useQuery();
  const languageFromUrl = query.get("appLanguage");
  const isLanguageFromUrlExist = languageFromUrl && languageFromUrl !== "null";
  const isLanguageSavedInStorage = useMemo(() => {
    const storedLanguage = localStorage.getItem("AppLanguage");
    return storedLanguage && storedLanguage !== "null";
  }, []);

  const handleCheckUrlLang = useCallback(() => {
    if (isLanguageFromUrlExist && isLanguageSavedInStorage) {
      const appLanguage = query.get("appLanguage");
      localStorage.setItem("AppLanguage", appLanguage);
      i18n.changeLanguage(appLanguage);
    }
  }, [i18n, query, isLanguageFromUrlExist, isLanguageSavedInStorage]);

  useEffect(() => {
    handleCheckUrlLang();
  }, [handleCheckUrlLang]);

  return (
    <PageWrapper>
      <Box className={classes.container}>
        <Box className={classes.block}>
          <Typography variant="h2"><b className={classes.highlighted}>{t("PRIVACY")}</b> {t("POLICY")}</Typography>
          <Typography className={classes.mainDate}>
            {t("Last update: 23 March 2022")}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.block}>
            <Typography color='textSecondary'>{t(" І. The Policy: ")}</Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Trans>
                    {
                      "1. This Privacy Policy ('Privacy Policy') regulates the collection, use, and disclose of Personal Data and other information when You use Cryptoneed ('Wallet')."
                    }
                  </Trans>
                  <br />
                  {t(
                    "Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Use."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Trans>
                    {"2. The Privacy Policy is part of Terms of Use."}
                  </Trans>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "If you do not agree with this Privacy Policy, please do not use the Wallet."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4. "}
                  {t(
                    "Please read the following terms carefully before you start using Cryptoneed (can be called as the Wallet). Upon use of the Wallet, you certify that you consent to Privacy Policy and also  accept Terms of Use."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"5. "}
                  {t(
                    "The Wallet is intended solely for use by a legal entity or full-aged individual (18 years). Use of the Wallet by non-adult is prohibited, we are not responsible for any consequences caused by the use of Wallet by non-adult."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"6. "}
                  {t(
                    "By providing us with Your personal information, You also consent for us to collect, hold, use and disclose your Personal Data in accordance with this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"ІI. "}
              {t(" Definition:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "Personal Data means data about a living individual who can be identified from those data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Usage Data is data collected automatically, either generated by the use of the Wallet or from the infrastructure itself (for example, the duration of a page visit)."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Data Controller means person who determines the purposes for which and the manner in which any personal information are, or are to be, processed."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "For the purpose of this Privacy Policy, we are a Data Controller of Your Personal Data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("If You have any questions, contact us by:")}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel='noreferrer'
                    target='_blank'>
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "Data Subject (or You) is any living individual who is using our Wallet and is the subject of Personal Data."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"III. "}
              {t(" Legal grounds for the processing of Your Personal Data:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1) "}
                  {t(
                    "consent to the processing of Personal Data received from You;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2) "}
                  {t(
                    "processing is necessary for the performance of a contract with You stated in Terms of Use;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3) "}
                  {t(
                    "processing is necessary for compliance with a legal obligation to which the Data controller is subject;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4) "}
                  {t(
                    "processing is in our legitimate interests and is not overridden by Your rights."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"IV. "}
              {t(" Types of information we collect:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "When you use the Wallet or/ and visit our Website, You may provide us with such types of information:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>{t("Usage Data")}</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        " Personal Data which we collect for the providing of certain Feature of the Wallet"
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t("You must not register to use the Wallet.")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "When You use the Wallet You do not need to provide us with Personal Data unless otherwise specified in this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"A. "} {t("Usage Data")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1) "}
                  {t(
                    "With regard to each of Your visits to our Website or with our mobile application we automatically may collect the Usage Data - following information:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Information that Your browser sends whenever You use of the Wallet"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Information about Your use of the Wallet, including the type of browser and version You use;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The pages that You visit, the time and date of Your visit, the time spent on those pages, the last access time of Your Wallet;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "Information about the device you use including the hardware model, operating system and version, and unique device identifiers and other diagnostic data, but this information is anonymized and not tied to any particular person."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2) "}
                  {t(
                    "Cookies. We use cookies and similar tracking technologies to track the activity on the Wallet and hold certain information."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "You can easily remove any cookies that have been created in the cookie folder on Your browser setting."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "You can instruct Your browser to refuse all cookies or to indicate where a cookie is being sent."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"B. "}
              {t(
                " Personal Data which we collect for the providing of certain Feature of the Wallet"
              )}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1) "}
                  {t(
                    "To perform Transaction between Virtual Currency and Fiat Money we collect following information about your bank details:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>{t("bank card number;")}</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>{t("expiration date.")}</ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2) "}
                  {t(
                    "We collect Personal Data by receiving the above information from You and use it purpose states in this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3) "}
                  {t(
                    "By submitting your bank details, you grant Use the right to share the Personal Data/ information about your bank details with third parties/service providers for purposes of fulfillment of Transactions."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4) "}
                  {t(
                    "We share Your Personal Data with third parties/service providers with which we have signed agreements to make sure that Your Personal Data is secured."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"5) "}
                  {t(
                    "By submitting your Personal Data/ bank details, You agree that we do not bear any responsibility for the actions of such third parties/service providers, nor any actions of any other person, including actions related to the collection, processing, and transmission of such information. You agree that we are not liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses as a result of transferring by you to us Personal Data / information about your bank details."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"6) "}
                  {t(
                    "If no transactions between Virtual Currency and Fiat Money occur, the Personal Data/ the information about bank details are not collected."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"V. "}
              {t("Purposes of collecting Personal Data / bank details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t("maintaining and providing You with the Wallet;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "providing, maintaining, delivering or improving our Website, the Wallet provided through our mobile application;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("managing and developing our business and operations;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "making more of such services available to You where possible and appropriate"
                  )}
                  ;
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("promotion of the Wallet and other marketing purposes;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("fulfilling our legal or regulatory requirements;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("developing new products or services;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t("protecting our rights and property;")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "providing You with the information or services that You have requested;"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "carrying out any actions for which we have received Your consent, carrying out any other purpose or reason for which the information was collected."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"VI. "}
              {t("Disclosure of Personal Data/ bank details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "We will only disclose Personal Data/ information about Your bank details in the following ways:"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t("with Your consent or at Your instructions;")}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "with third parties that perform work for us for providing You with certain features of the Wallet;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "certain information You may choose to share may be displayed publicly;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "in response to a request for information if we believe disclosure is in accordance with or required by, any applicable law, regulation or legal process."
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "We collect, use, process, disclose and transfer your Personal Data /information about your bank details only in purpose to perform Transaction between Virtual Currency and Fiat Money."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "Terms associated with the collection, using, processing, disclosing, transferring Personal Data and information about your bank details to a third party, liability, etc., are set out in section B of Chapter IV this Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"VII. "}
              {t("Security of Personal Data / bank details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {t(
                    "We protect Personal Data / bank details with appropriate physical, technological and organizational safeguards and security measures."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {t(
                    "The access to Personal data is restricted and certain access rights are set."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"VIII. "}
              {t("Storage / Retention of Your Personal Data / bank details:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "We store your Personal Data/ bank details within Application in an encoded format."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "We do not store your Personal Data/ information about bank details on servers."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "We confirm that any third-party with whom we share Your Personal Data will provide the same or equal protection of user data as stated in this Privacy Policy and required by law."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4. "}
                  {t(
                    "We retain the information of You only as long as it is necessary, in accordance with the purpose of collecting the data and required by law. When Personal Data and other information are no longer needed we have procedures either to destroy, delete, erase or convert it to an anonymous form."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"5. "}
                  {t(
                    "All Personal Data can be removed by You if You chose to. You can revoke consent and/or request deletion of Your Personal Data at any time contacting us at"
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel='noreferrer'
                    target='_blank'>
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"6. "}
                  {t(
                    "We reserve the right to store/process personal data in accordance with the specific purpose of their storage/processing without limitation of storage and processing time in cases stipulated by law."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"7. "}
                  {t(
                    "Withdrawal of consent and/or deletion of Your Personal Data disables the use of certain Feature of the Wallet by You."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"IX. "}
              {t(" Data subject’s rights:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t("You have the right to:")}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "a) modify and correct your Personal Data at any time and you are therefore responsible for the accuracy of Your Personal  Data;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "b) ask about Your Personal Data from us to data portability and also the right to request us to restrict the processing of Your Personal Data and/or to delete it in case obligation to retain the data is not set out by the law;"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "c) object to processing of Your Personal Data and/or to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal;"
                      )}
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "Legal Basis for Processing Personal Data in the European Economic Area (EEA) stated in the General Data Protection Regulation (GDPR). We aim to take reasonable steps to allow You to correct, amend, delete, or limit the use of Your Personal Data."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "If You are a resident of the European Economic Area (EEA), You have certain data protection rights stated in the General Data Protection Regulation:"
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right to access, update or to delete the information we have on You. Whenever made possible, You can access, update or request the deletion of Your Personal Data directly. If You are unable to perform these actions yourself, please contact us to assist You."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right of rectification. You have the right to have Your information rectified if that information is inaccurate or incomplete."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right to object. You have the right to object to our processing of Your Personal Data."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right of restriction. You have the right to request that we restrict the processing of Your personal information"
                      )}
                      .
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "The right to withdraw consent. You also have the right to withdraw Your consent at any time where we relied on Your consent to process Your personal information."
                      )}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {t(
                        "If You wish to be informed what Personal Data we hold about You and if You want it to be removed from our systems, please contact us at"
                      )}
                      <a
                        className={classes.link}
                        href={`mailto:${SUPPORT_EMAIL}`}
                        rel='noreferrer'
                        target='_blank'>
                        {SUPPORT_EMAIL}
                      </a>
                      .
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t("You can exercise your rights by contacting us at")}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel='noreferrer'
                    target='_blank'>
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"4. "}
                  {t(
                    "You have the right to complain to a Data Protection Authority about our collection and use of Your Personal Data. For more information, please contact Your local data protection authority in the European Economic Area (EEA)."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"X. "}
              {t("Children's Privacy:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "Wallet does not address anyone under the age of 18 ('Children')."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that your Children has provided us with Personal Data, please contact us at"
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel='noreferrer'
                    target='_blank'>
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary'>
              {"XI. "}
              {t("Amendments")}
              {":"}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "We may change, amend, delete or add this Privacy Policy at any time."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "Any such changes will be effective upon the posting on a Website or mobile application. The effective date which is at the top of the Privacy Policy informs You about the latest version of Privacy Policy."
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"3. "}
                  {t(
                    "Your continued use of the Wallet and/or Website constitutes Your acceptance of such changes and Your consent to the processing of Personal Data according to the latest version. If You do not agree with any such changes, do not continue to use the Wallet and/or Website."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box className={classes.block}>
            <Typography color='textSecondary' style={{ textAlign: "justify" }}>
              {"XII. "}
              {t("Questions and Complaints:")}
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  {"1. "}
                  {t(
                    "Any questions about this Policy, the collection, use, and disclosure of Personal Data or access to Your Personal Data which is required by law (or would be so subject had the storage in question taken place in a relevant EU member state if the case may be but not otherwise) to be disclosed should be directed to"
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel='noreferrer'>
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  {"2. "}
                  {t(
                    "In the event that You wish to make a complaint about how we process your personal data, please contact us in the first instance at"
                  )}
                  <a
                    className={classes.link}
                    href={`mailto:${SUPPORT_EMAIL}`}
                    rel='noreferrer'
                    target='_blank'>
                    {SUPPORT_EMAIL}
                  </a>{" "}
                  {t(
                    "and we will endeavor to deal with your request as soon as possible."
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 auto",
    color: "#ffffff",
    marginTop: '40px',
  },
  content: {
    background: '#212328',
    padding: '32px',
    borderRadius: '20px',
  },
  mainDate: {
    paddingLeft: '20px',
    color: theme.palette.text.quaternary,
    marginTop: '10px',
    marginBottom: '20px'
  },
  highlighted: {
    color: theme.palette.text.tertiary,
  },
  block: {
    marginBottom: "24px",
    color: "#ffffff",
    "& li": {
      textAlign: "justify",
      paddingLeft: 24,
      paddingRight: 0,
    },
  },
  link: {
    color: theme.palette.action.active,
    fontWeight: "600",
  },
}));
