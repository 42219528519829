import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { ReactComponent as SentIcon } from "../../../../assets/sent.svg";
import { ReactComponent as ReceiveIcon } from "../../../../assets/receive.svg";
import { roundValue } from "../../../../utils/functions/roundValue";
import { getTicker } from "../../../../utils/functions/getTicker";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import { coloredFirstLetter } from "../../../../utils/functions/coloredFirstLetter";
import { theme } from "../../../../theme/theme";

const size = 10;

// MOCK EXAMPLE
// const history = {
//   content: [
//     {
//       type: 'SELL_TOKEN',
//       status: 'Completed',
//       tokenAmount: 0.44,
//       token: 'DSG',
//       asset: "USDT",
//       tokenPrice: 12,
//       time: '2023-07-27T10:05:13.957193Z'
//     },
//     {
//       type: 'BUY_TOKEN',
//       status: 'Completed',
//       tokenAmount: 0.12,
//       token: 'DSG',
//       asset: "USDT",
//       tokenPrice: 332,
//       time: '2023-07-27T10:05:13.957193Z'
//     },
//   ]
// }

const History = ({ history, page, changePage, settings }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography
        variant={"h4"}
        className={classes.title}
      >
        {coloredFirstLetter("Transaction history", theme.palette.text.quaternary)}
      </Typography>
      {history?.content?.length === 0 ? (
        <Typography
          variant={"body2"}
          color={"textSecondary"}
          style={{
            textAlign: "center",
            marginTop: 24,
          }}
        >
          {"You have no transactions..."}
        </Typography>
      ) : (
        history?.content.map((transaction, index) => (
          <Transaction
            key={index}
            transaction={transaction}
            settings={settings}
          />
        ))
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 32,
        }}
      >
        <StyledPagination
          count={Math.ceil(history?.totalElements / size)}
          page={page + 1}
          boundaryCount={1}
          siblingCount={1}
          color="secondary"
          onChange={(event, page) => changePage(page - 1)}
        />
      </div>
    </Paper>
  );
};

export default History;

const StyledPagination = withStyles({
  root: {},
  ul: {
    "& li": {
      "& button": {
        "&.Mui-selected": {
          background: "#2A2E3A",
        },
      },
      "& button:hover": {
        background: "#2A2E3A",
      },
    },
  },
})(Pagination);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "32px 24px",
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: 32,
  },
}));

export const Transaction = ({ transaction, settings }) => {
  const classes = useTransactionsStyles();

  return (
    <>
      <Grid container spacing={1} alignItems={"center"} wrap="nowrap">
        <Grid item>
          {transaction?.type?.startsWith("SELL") ? (
            <SentIcon width={20} height={20} />
          ) : (
            <ReceiveIcon width={20} height={20} />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems={"center"} className={classes.col}>
            <Grid item>
              <Typography
                variant={"body1"}
                color="textPrimary"
                noWrap
                style={{ fontWeight: 600 }}
              >
                {transaction?.type}
              </Typography>
              <Typography
                variant={"caption"}
                noWrap
                color="textSecondary"
                style={{
                  textOverflow: "ellipsis",
                  fontWeight: 400,
                  color: transaction?.status
                    ? getStatusColor(transaction?.status)
                    : "#49AB5B",
                }}
              >
                {transaction?.status || "Completed"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems={"center"} className={classes.col}>
            <Grid item>
              <Typography variant={"body1"} style={{ fontWeight: 700 }} noWrap>
                {transaction?.tokenAmount}{" "}
                <span style={{ fontWeight: 400 }}>
                  {transaction?.token.slice(0, 3)}
                </span>
              </Typography>
              <Typography variant={"caption"} color={"textSecondary"} noWrap>
                {roundValue(
                  transaction?.tokenAmount * transaction?.tokenPrice,
                  settings?.minScale
                )}{" "}
                {getTicker(transaction?.asset)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            justifyContent={"flex-end"}
            className={classes.col}
            direction="column"
          >
            <Typography variant={"caption"} color={"textSecondary"}>
              {moment(transaction?.time).format("DD.MM.yyyy")}
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              {moment(transaction?.time).format("HH:mm:ss")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const useTransactionsStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 24,
    padding: "4px 12px",
    [theme.breakpoints.down("sm")]: {
      // padding: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px 0px 8px",
      marginBottom: 16,
    },
  },
  col: {
    padding: "6px 8px 6px 0px",
  },
  type: {
    display: "flex",
    alignItems: "center",
    "&>svg": {
      width: 36,
      height: 36,
    },
  },
  amount: {
    textAlign: "right",
    "&.sent>p": {
      color: theme.palette.success.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  details: {
    textAlign: "right",
    textOverflow: "ellipsis",
    "& button": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },

  btnSmall: {
    "&.MuiButton-contained ": {
      maxHeight: 32,
      minWidth: 96,
      color: theme.palette.success.main,
      background: "rgba(73, 171, 91, 0.3)",
      marginLeft: 12,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        minWidth: 73,
        padding: 0,
      },
      [theme.breakpoints.down(400)]: {
        fontSize: 10,
        minWidth: 65,
        padding: 0,
      },
    },
  },
}));
