import {connect} from 'react-redux';
import {Redirect, Route} from "react-router-dom";

const PrivateRoute = ({authorized, children, ...routeProps}) => {
	return (
		<Route
			{...routeProps}
			render={({ location }) =>
				authorized ? children :
				<Redirect
					to={{
						pathname: "/login",
						state: { from: location }
					}}
				/>
			}
		/>
	)
}

const mapStateToProps = ({account}) => ({
	authorized: account.authorized
});

export default connect(mapStateToProps)(PrivateRoute);
