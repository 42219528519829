import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import PageWrapper from "../../theme/PageWrapper";
import Fund from "./Funds/Fund";

import { getAllInvestmentIndex } from "../../redux/actions/indexesActions";
import { getAllInvestmentFunds } from "../../redux/actions/fundActions";
import {
  getAllTickerSettings,
  getWalletsList,
} from "../../redux/actions/walletsActions";
import IndexItem from "./Indexes/IndexItem";
import SwitcherBlock from "./SwitcherBlock";
import Breadcrumb from "../../theme/components/Breadcrumb";

export const CapitalTabs = {
  'fund': 0,
  'index': 1,
}

const Capital = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { fundList } = useSelector(({ funds }) => funds);
  const { indexesList } = useSelector(({ indexes }) => indexes);
  const [valueTab, setValueTab] = useState(CapitalTabs.fund);

  useEffect(
    () =>
      history?.location?.state?.tab
        ? setValueTab(history?.location?.state?.tab)
        : 0,
    [history?.location?.state]
  );

  useEffect(() => {
    dispatch(getAllInvestmentIndex());
    dispatch(getAllInvestmentFunds());
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getAllTickerSettings());
  }, [dispatch]);

  const handleChangeTab = (tab) => {
    setValueTab(tab);
  };

  return (
    <PageWrapper>
      <Grid item className={classes.breadcrumbWrap}>
        <Breadcrumb to={"/"} pageName={"Main"} />
      </Grid>
      <Grid spacing={3} container direction="row" className={classes.root}>
        {valueTab === CapitalTabs.fund &&
          <Grid item xs={12} md={8} direction="column" wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
            {fundList?.length > 0 &&
              fundList.map((fund, index) => (
                <Grid
                  xs={12}
                  item
                  key={fund.id || index}
                  container
                  className={classes.block}
                >
                  <Fund fund={fund}></Fund>
                </Grid>
              ))}
          </Grid>
        }
        {
          valueTab === CapitalTabs.index &&
          <Grid item xs={12} md={8} container direction="column" wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
            {indexesList?.length > 0 &&
              indexesList.map((item, index) => (
                <Grid className={classes.block} container xs={12} item key={item.id || index}>
                  <IndexItem item={item}></IndexItem>
                </Grid>
              ))}
          </Grid>
        }
        <Grid item xs={12} md={4}>
          <SwitcherBlock activeTab={valueTab} onTabChange={handleChangeTab} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Capital;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 34,
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.down("xs")]: {
    },
  },

  block: {
    marginBottom: 24,
  },

  breadcrumbWrap: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
    [theme.breakpoints.down(725)]: {
      // width: "100%",
    },
  },
}));
