import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Grid,
} from "@material-ui/core";
import CloseIcon from "../../../assets/icons/close.svg";
import { OPERATION_TYPES } from "../../../utils/constants/capital";
import Sale from "../Funds/FundDetails/Sale";
import Invest from "../Funds/FundDetails/Invest";
import { closeTradeDialog } from "../../../redux/actions/dialogActions";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TradeDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { typeTrade, item, settings, userBalanceAsset, isIndexes } =
    useSelector(({ dialogs }) => dialogs.trade);

  const handleClose = () => {
    dispatch(closeTradeDialog());
  };

  return (
    <Dialog
      open={Boolean(typeTrade)}
      TransitionComponent={Transition}
      fullWidth={false}
      keepMounted
      maxWidth={false}
      className={classes.root}
      onClose={handleClose}
    >
      <Paper elevation={4} className={classes.paper}>
        <Grid container justifyContent="flex-end" className={classes.header}>
          <Grid item>
            <IconButton size={"small"} onClick={handleClose}>
              <img src={CloseIcon} alt="close" />
            </IconButton>
          </Grid>
        </Grid>
        <div className={classes.box}>
          {typeTrade === OPERATION_TYPES.BUY_TOKEN ? (
            <Invest
              item={item}
              settings={settings}
              balance={userBalanceAsset}
              isIndexes={isIndexes}
            />
          ) : typeTrade === OPERATION_TYPES.SELL_TOKEN ? (
            <Sale item={item} settings={settings} isIndexes={isIndexes} />
          ) : null}
        </div>
      </Paper>
    </Dialog>
  );
};

export default TradeDialog;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  paper: {
    maxWidth: '660px',
    width: '100%',
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    paddingBottom: 16,
  },
  box: {
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
}));
