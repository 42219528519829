import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loading from "./theme/components/Loading";
import PrivateRoute from "./utils/PrivateRoute";
import Capital from "./pages/Capital";
// import Transactions from "./pages/Transactions";
// import WalletDetails from "./pages/WalletsDetails/index";
import DoneIcon from "@material-ui/icons/Done";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Notifier from "./utils/Notifier";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Button } from "./theme/components/Button";
import { useDispatch } from "react-redux";
import { closeSnackbar } from "./redux/actions/notistackActions";
import FundDetails from "./pages/Capital/Funds/FundDetails/index";
import IndexDetails from "./pages/Capital/Indexes/IndexDetails/index";

// import AddNewWalletDialog from "./theme/dialogs/AddNewWalletDialog";
// import ReceiveCardDialog from "../src/pages/Receive/fiat/ReceiveCardDialog";
// import Send from "./pages/Send";
// import Settings from "./pages/Settings";
// import Exchange from "./pages/Exchange";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
// import { useCallback, useEffect, useRef } from "react";
// import { getMarketList } from "./redux/actions/marketActions";
import Loader from "./pages/Loader";
// import KYC from "./pages/KYC";

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const timeOut = useRef();
  // const { authorized } = useSelector(({ account }) => account);
  // const getMarkets = useCallback(() => {
  //   if (authorized) {
  //     dispatch(getMarketList());
  //     clearTimeout(timeOut.current);
  //     timeOut.current = setTimeout(() => {
  //       getMarkets();
  //     }, 30000);
  //   } else {
  //     clearTimeout(timeOut.current);
  //   }
  // }, [dispatch, authorized]);

  // useEffect(() => {
  //   getMarkets();
  //   return () => {
  //     clearTimeout(timeOut.current);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getMarkets, authorized]);

  return (
    <SnackbarProvider
      action={(key) => (
        <Button
          onClick={() => dispatch(closeSnackbar(key))}
          style={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        />
      )}
      iconVariant={{
        success: <DoneIcon />,
        error: <NotInterestedIcon />,
        warning: <ErrorOutlineOutlinedIcon />,
        info: <InfoOutlinedIcon />,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        variantSuccess: clsx(classes.root, classes.success),
        variantError: clsx(classes.root, classes.error),
        variantWarning: clsx(classes.root, classes.warning),
        variantInfo: clsx(classes.root, classes.info),
      }}
    >
      <Notifier />
      {/* <AddNewWalletDialog /> */}
      {/* <ReceiveCardDialog /> */}
      <BrowserRouter>
        <Loading />
        <Switch>
          <PrivateRoute path="/" exact>
            <Capital />
          </PrivateRoute>
          <Route path="/login">
            <Loader />
          </Route>
          <PrivateRoute path="/capital/fund/:id" exact>
            <FundDetails />
          </PrivateRoute>
          <PrivateRoute path="/capital/index/:id" exact>
            <IndexDetails />
          </PrivateRoute>
          <Route path="/privacy" exact>
            <Privacy />
          </Route>
          <Route path="/terms" exact>
            <Terms />
          </Route>
          {/*<PrivateRoute path='/' exact>
            <Wallets />
          </PrivateRoute>
          <PrivateRoute path='/wallet/:ticker' exact>
            <WalletDetails />
          </PrivateRoute>
          <PrivateRoute path='/send' exact>
            <Send />
          </PrivateRoute>
          <PrivateRoute path='/exchange' exact>
            <Exchange />
          </PrivateRoute>
          <PrivateRoute path='/settings' exact>
            <Settings />
          </PrivateRoute>
          <PrivateRoute path='/transactions' exact>
            <Transactions />
          </PrivateRoute>
          <PrivateRoute path='/kyc' exact>
            <KYC />
          </PrivateRoute> */}
          <Redirect to={"/"} />
        </Switch>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    padding: "8px 16px",
    "&>*": {
      fontSize: 12,
      lineHeight: "16px",
      maxWidth: 220,
      fontWeight: 500,
    },
    "& svg": {
      fontSize: 24,
      marginRight: 11,
    },
  },
  success: {
    backgroundColor: "#36523E",
    "& svg": {
      color: "#5BB55D",
    },
  },
  error: {
    backgroundColor: "#5F3238",
    "& svg": {
      color: "#FF2F51",
    },
  },
  warning: {
    backgroundColor: "#655129",
    "& svg": {
      color: "#F8B017",
    },
  },
  info: {
    backgroundColor: "#21354C",
    "& svg": {
      color: "#18A0FB",
    },
  },
}));
