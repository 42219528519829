import { useState, useMemo } from "react";
import { useLocation } from "react-router";
import { connect, useSelector } from "react-redux";
import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
//
import { Button } from "../../Button";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openInfoDialog } from "../../../../redux/actions/dialogActions";
import { backToMain, logOut } from "../../../../redux/actions/accountActions";
import HomeIcon from "@material-ui/icons/Home";
import Logout from "../../../../assets/icons/menu/logout.svg";
import { MENU_LIST } from "../../../../utils/constants/header";

const HeaderMenu = ({ dispatch }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let currentlyHovering = false;
  const location = useLocation();
  const { t } = useTranslation();
  const { data } = useSelector(({ account }) => account);

  const selectedMenuItem = useMemo(() => {
    const item = {
      ...MENU_LIST.find(
        (item) =>
          location.pathname.includes("capital") ||
          location.pathname === item.path
      ),
    };
    return item;
  }, [location.pathname]);

  const loginName = useMemo(() => {
    if (data && data?.login) {
      return data.login;
    }else {
      return selectedMenuItem?.title
    }
  }, [data, selectedMenuItem?.title])

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setTimeout(setAnchorEl(event.currentTarget), 200);
    }
  };

  function handleHover() {
    currentlyHovering = true;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCloseHover() {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  }

  const handleSupportClick = () => {
    dispatch(openInfoDialog());
  };

  return (
    <>
      <Button
        variant={"outlined"}
        className={classes.menuBtn}
        onClick={handleClick}
        onMouseOver={handleClick}
        onMouseLeave={handleCloseHover}
      >
        {t(loginName)}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          onMouseEnter: handleHover,
          onMouseLeave: handleCloseHover,
          style: { pointerEvents: "auto" },
        }}
        getContentAnchorEl={null}
        PopoverClasses={{
          root: classes.popOverRoot,
        }}
      >
        <StyledMenuItem onClick={() => dispatch(backToMain())}>
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Main page")} />
        </StyledMenuItem>
        {MENU_LIST &&
          MENU_LIST?.map((item, i) => (
            <StyledMenuItem
              key={i}
              component={NavLink}
              exact
              onClick={() => {
                item.path === "/support" && handleSupportClick();
              }}
              to={item.path}
            >
              <ListItemIcon>
                <img src={item.src} alt={item.altImg} loading={"lazy"} />
              </ListItemIcon>
              <ListItemText primary={t(item.title)} />
            </StyledMenuItem>
          ))}
        <StyledMenuItem onClick={() => dispatch(logOut())}>
          <ListItemIcon>
            <img src={Logout} alt={"logOut"} loading={"lazy"} />
          </ListItemIcon>
          <ListItemText primary={t("Log out")} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const mapStateToProps = ({ account }) => ({
  data: account.data,
});

export default connect(mapStateToProps)(HeaderMenu);

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 8,
    border: '1px solid #333'
  },
}))((props) => (
  <Menu
    elevation={12}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingBottom: "0px",
    paddingTop: "0px",
    "& .MuiMenuItem-root": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },

    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      color: theme.palette.common.white,
      minWidth: 28,
      margin: 4,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "19.5px",
    },

    "&.active:not(:hover)": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  menuBtn: {
    fontSize: '12px',
    minHeight: 34,
    "&.MuiButton-outlined": {
      minWidth: 100,
      backgroundColor: theme.palette.primary.light,
      borderWidth: '1px',
      borderColor: '#484E5A',
      [theme.breakpoints.down(800)]: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.default
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    "& img": {
      marginLeft: 11,
    },
    "& .MuiButton-label": {
      color: theme.palette.text.darkLighter,
      [theme.breakpoints.down(800)]: {
        color: theme.palette.text.primary
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginLeft: 16,
      "& img": {
        marginLeft: 8,
        width: 12,
        height: 12,
      },
    },
  },

  widget: {
    display: "flex",
    alignItems: "center",
    marginLeft: 12,
    padding: "4px 8px",
    borderRadius: 4,
    fontWeight: 400,
    "& img": {
      marginRight: 6,
      width: 12,
      height: 12,
    },
  },
  confirmed: {
    background: "#008C39",
  },
  pending: {
    background: "#F9B630",
  },
  notConfirmed: {
    background: "#2A2E3A",
  },
  linkWrap: {
    paddingLeft: "calc(100% / 5)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
  },
  popOverRoot: {
    pointerEvents: "none",
  },
}));