import {
  ACCOUNT_ACTIVATE_FAILURE,
  ACCOUNT_ACTIVATE_STARTED,
  ACCOUNT_ACTIVATE_SUCCESS,
  ACCOUNT_LOGIN_FAILURE,
  ACCOUNT_LOGIN_STARTED,
  ACCOUNT_LOGIN_SUCCESS,
  LOGIN_TWO_FA_SUCCESS,
  ACCOUNT_REGISTER_FAILURE,
  ACCOUNT_REGISTER_STARTED,
  ACCOUNT_REGISTER_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  FINISH_RESET_PASSWORD_FAILURE,
  FINISH_RESET_PASSWORD_STARTED,
  FINISH_RESET_PASSWORD_SUCCESS,
  GET_ACCOUNT_DATA_FAILURE,
  GET_ACCOUNT_DATA_STARTED,
  GET_ACCOUNT_DATA_SUCCESS,
  LOG_OUT,
  BACK_TO_MAIN,
  RESET_PASSWORD_INIT_FAILURE,
  RESET_PASSWORD_INIT_STARTED,
  RESET_PASSWORD_INIT_SUCCESS,
  RESET_PASSWORD_RESET,
  RESET_REGISTER_STATUS,
  ENABLE_TWO_FA_STARTED,
  ENABLE_TWO_FA_SUCCESS,
  ENABLE_TWO_FA_FAILURE,
  DISABLE_TWO_FA_STARTED,
  DISABLE_TWO_FA_SUCCESS,
  DISABLE_TWO_FA_FAILURE,
  CHECK_IS_TWO_FA_STARTED,
  CHECK_IS_TWO_FA_SUCCESS,
  CHECK_IS_TWO_FA_FAILURE,
  CHECK_CODE_TWO_FA_STARTED,
  CHECK_CODE_TWO_FA_SUCCESS,
  CHECK_CODE_TWO_FA_FAILURE,
  CHECK_INSTALL_TWO_FA_STARTED,
  CHECK_INSTALL_TWO_FA_SUCCESS,
  CHECK_INSTALL_TWO_FA_FAILURE,
  CLEAR_TWO_FA_QR_CODE,
  GET_ACCOUNT_PERMISSIONS_INFO,
} from "../types";

export default function account(
  state = {
    authorized: false,
    registerSuccess: false,
    activateSuccess: false,
    resetPasswordSuccess: false,
    permissions: {
      accountBlocked: false,
      withdrawBlocked: false,
      exchangeBlocked: false,
    },
    data: null,
    error: null,
    passError: null,
    isTwoFA: false,
    qrCode: "",
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_REGISTER_STARTED:
    case ACCOUNT_ACTIVATE_STARTED:
    case ACCOUNT_LOGIN_STARTED:
    case GET_ACCOUNT_DATA_STARTED:
    case RESET_PASSWORD_INIT_STARTED:
    case FINISH_RESET_PASSWORD_STARTED:
    case ENABLE_TWO_FA_STARTED:
    case DISABLE_TWO_FA_STARTED:
    case CHECK_IS_TWO_FA_STARTED:
    case CHECK_CODE_TWO_FA_STARTED:
    case CHECK_INSTALL_TWO_FA_STARTED:
    case GET_ACCOUNT_PERMISSIONS_INFO.REQUEST:
      return { ...state, error: null };
    case CHANGE_PASSWORD_STARTED:
      return { ...state, passError: null };
    case ACCOUNT_REGISTER_SUCCESS:
      return { ...state, registerSuccess: true };
    case ACCOUNT_ACTIVATE_SUCCESS:
      return { ...state, activateSuccess: true };
    case ACCOUNT_LOGIN_SUCCESS:
      return { ...state, authorized: true };
    case GET_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        authorized: true,
      };
    case LOGIN_TWO_FA_SUCCESS:
      return { ...state, isTwoFA: true, authorized: false };

    case RESET_PASSWORD_INIT_SUCCESS:
    case FINISH_RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: true };

    case CHECK_IS_TWO_FA_SUCCESS:
      return { ...state, isTwoFA: action.payload, authorized: !action.payload };
    case CHECK_INSTALL_TWO_FA_SUCCESS:
      return {
        ...state,
        isTwoFA: action.payload,
      };
    case CHECK_CODE_TWO_FA_SUCCESS:
      return { ...state, authorized: action.payload };
    case ENABLE_TWO_FA_SUCCESS:
      return { ...state, qrCode: action.payload };
    case DISABLE_TWO_FA_SUCCESS:
    case CLEAR_TWO_FA_QR_CODE:
      return { ...state, qrCode: "" };

    case ACCOUNT_LOGIN_FAILURE:
      return { ...state, error: action.payload.error };
    case ACCOUNT_REGISTER_FAILURE:
    case ACCOUNT_ACTIVATE_FAILURE:
    case GET_ACCOUNT_DATA_FAILURE:
    case RESET_PASSWORD_INIT_FAILURE:
    case FINISH_RESET_PASSWORD_FAILURE:
    case ENABLE_TWO_FA_FAILURE:
    case DISABLE_TWO_FA_FAILURE:
    case CHECK_IS_TWO_FA_FAILURE:
    case CHECK_CODE_TWO_FA_FAILURE:
    case CHECK_INSTALL_TWO_FA_FAILURE:
      return { ...state, error: action.payload.error };
    case CHANGE_PASSWORD_FAILURE:
      return { ...state, passError: action.payload.error };
    case RESET_REGISTER_STATUS:
      return { ...state, registerSuccess: false };
    case RESET_PASSWORD_RESET:
      return { ...state, resetPasswordSuccess: false };
    case GET_ACCOUNT_PERMISSIONS_INFO.SUCCESS:
      return { ...state, permissions: action.payload };
    case LOG_OUT:
    case BACK_TO_MAIN:
      return {
        ...state,
        data: null,
        error: null,
        isTwoFA: false,
        authorized: false,
      };
    default:
      return state;
  }
}
