import { connect } from "react-redux";
import { makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { EXTERNAL_WALLET_APP_ROUTE_PATH_NAME, HEADER_MOBILE_NAV_LIST, HEADER_NAV_LIST, PRIVACY_TERMS_NAV_LIST } from "../../../../utils/constants/header";
import {
  WALLET_BASE_URL,
  EXTERNAL_BASE_URL,
} from "../../../../utils/constants/api";
import { openInfoDialog } from "../../../../redux/actions/dialogActions";
import { useHistory } from "react-router";

const getWalletRedirectExternalRoute = (pathName = '') => {
  if (pathName?.includes(`${EXTERNAL_WALLET_APP_ROUTE_PATH_NAME}/`)) {
    return `${pathName}`.replace(`${EXTERNAL_WALLET_APP_ROUTE_PATH_NAME}/`, '');
  } else if (pathName?.includes(EXTERNAL_WALLET_APP_ROUTE_PATH_NAME)) {
    return '/'
  }
  return '/'
}

const NavigationMenu = ({ dispatch }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory()
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  const handleMenuClick = (pathName) => {
    if (pathName === "/") {
      history.push(pathName)
      return;
    }
    if (pathName === '/support') {
      handleSupportClick();
      return;
    }
    if (pathName === '/privacy' || pathName === '/terms') {
      history.push(pathName)
      return;
    }
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const appLanguage = localStorage.getItem("AppLanguage");
    window.open(
      `${pathName?.includes(EXTERNAL_WALLET_APP_ROUTE_PATH_NAME)
        ? WALLET_BASE_URL
        : // : pathName?.includes("capital")
        // ? "/"
        EXTERNAL_BASE_URL
      }?access_token=${accessToken}&refresh_token=${refreshToken}&path_name=${getWalletRedirectExternalRoute(pathName) || "/"
      }&appLanguage=${appLanguage}`,
      "_self"
    );
    localStorage.clear();
  };

  const handleSupportClick = () => {
    dispatch(openInfoDialog());
  };

  return (
    <>
      <div className={classes.container}>
        {(isMobile ? HEADER_MOBILE_NAV_LIST : HEADER_NAV_LIST).map((nav, index) => (
          <div
            className={classes.itemWrapper}
            key={index}
            onClick={() => handleMenuClick(nav?.href)}
          >
            <Typography
              className={clsx(
                classes.item,
                nav?.href === "/" && classes.active
              )}
              key={nav?.href + index}
            >
              {t(nav.label)}
            </Typography>
          </div>
        ))}
        {isMobile ? <span className={classes.spacing} /> : null}
        {isMobile ? PRIVACY_TERMS_NAV_LIST.map((nav, index) => (
          <div
            className={classes.itemWrapper}
            key={index}
            onClick={() => handleMenuClick(nav.href)}
          >
            <Typography
              className={clsx(classes.item, classes.itemSmall, nav.href === "/" && classes.active)}
              key={nav.href + index}
            >
              {t(nav.label)}
            </Typography>
          </div>
        )) : null}
      </div>
    </>
  );
};

const mapStateToProps = ({ account }) => ({
  data: account.data,
});

export default connect(mapStateToProps)(NavigationMenu);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginRight: "20px",
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      padding: "10px",
      margin: "0px",
    },
    [theme.breakpoints.down(800)]: {
      flexDirection: "column",
      width: '100%',
    },
  },

  itemWrapper: {
    display: "flex",
  },

  item: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    textDecoration: "none",
    whiteSpace: "nowrap",
    position: "relative",
    cursor: "pointer",
    marginRight: '40px',
    "&::after": {
      content: '""',
      position: 'absolute',
      bottom: '-6px',
      left: '0px',
      width: '100%',
      height: '0.08em',
      backgroundColor: 'white',
      transition: 'opacity 300ms, transform 250ms',
      opacity: '1',
      transform: 'scale(0)',
      transformOrigin: 'center center'
    },
    "&:hover": {
      "&::after": {
        opacity: 1,
        transform: "scale(1)"
        // transform: "scale(1)",
      },
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: 16,
      lineHeight: "18px",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: '20px',
      lineHeight: '24px',
      marginBottom: '16px',
      fontWeight: 400,
      textTransform: 'uppercase',
      filter: "drop-shadow(2px 4px 2px rgb(0 0 0 / 0.4))",
    },
  },
  itemSmall: {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '16px',
    fontWeight: 400,
  },
  spacing: {
    height: 10,
  },
  active: {
    cursor: "default",
    "&::after": {
      content: '""',
      position: 'absolute',
      bottom: '-6px',
      left: '5%',
      width: '90%',
      height: '0.08em',
      backgroundColor: theme.palette.primary.main,
      transition: 'opacity 300ms, transform 250ms',
      opacity: '1',
      transform: 'scale(1)',
      transformOrigin: 'center center'
    },
  },
  divider: {
    margin: "0px 28px",
    width: "2px",
    backgroundColor: theme.palette.text.primary,
    [theme.breakpoints.down("lg")]: {
      margin: "0px 20px",
    },
    // [theme.breakpoints.down("md")]: {
    //   margin: "0 10px",
    // },
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
  },

  menuBtn: {
    "&.MuiButton-outlined": {
      minWidth: 114,
      [theme.breakpoints.down(800)]: {
        margin: 0,
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    "& img": {
      marginLeft: 11,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      "& img": {
        width: 12,
        height: 12,
      },
    },
  },
  linkWrap: {
    paddingLeft: "calc(100% / 5)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
    },
  },
  popOverRoot: {
    pointerEvents: "none",
  },
}));
