import { connect } from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Header from "./components/Header/Header";
import TradeDialog from "../pages/Capital/dialogs/TradeDialog";
import TradeSuccessDialog from "../pages/Capital/dialogs/TradeSuccessDialog";

const PageWrapper = ({ dispatch, children }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classes.secondaryBg)}>
      <Header />
      <Container maxWidth="lg">{children}</Container>
      <TradeDialog />
      <TradeSuccessDialog />
    </div>
  );
};

const mapStateToProps = ({ account }) => ({
  data: account.data,
});

export default connect(mapStateToProps)(PageWrapper);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 104,
    boxSizing: "border-box",
    minHeight: "100vh",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 80,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  secondaryBg: {
    background: 'radial-gradient(40% 440px at 52% 5%, #324B9280, #324B9280 0%, #324B9280 0%, #181818 80%)'
  }
}));
