import { useEffect, useState, useMemo } from "react";
import { Grid, makeStyles, Typography, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PageWrapper from "../../../../theme/PageWrapper";
import Breadcrumb from "../../../../theme/components/Breadcrumb";
import History from "./History";
import Invest from "./Invest";
import Sale from "./Sale";
import {
  getAllInvestmentFunds,
  getUserFundOperationsHistoryByToken,
} from "../../../../redux/actions/fundActions";
import {
  getAllTickerSettings,
  getWalletsList,
} from "../../../../redux/actions/walletsActions";
import { roundValue } from "../../../../utils/functions/roundValue";
import { getTicker } from "../../../../utils/functions/getTicker";
import Risk from "../../../../theme/components/Risk";

const FundDetails = () => {
  const classes = useStylesCardDetails();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [fund, setFund] = useState(null);
  const { fundList, operationsHistory } = useSelector(({ funds }) => funds);
  const { data, allTickerSettings } = useSelector(({ wallets }) => wallets);

  useEffect(() => {
    dispatch(getAllInvestmentFunds());
    dispatch(getWalletsList({ number: 0, size: 100 }));
    dispatch(getAllTickerSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!fund) {
      return;
    }
    dispatch(
      getUserFundOperationsHistoryByToken(fund?.token, { size: 10, page: page })
    );
  }, [dispatch, fund, page]);

  useEffect(() => {
    if (!id || !fundList) {
      return;
    }
    let fundItem = fundList?.find((i) => {
      return +i.id === +id;
    });
    setFund(fundItem);
  }, [dispatch, id, fundList]);

  const fundUserWallet = useMemo(() => {
    return data?.find((i) => i.ticker === fund?.asset);
  }, [data, fund]);

  const walletSettings = useMemo(() => {
    return allTickerSettings?.find((i) => i.ticker === fund?.asset);
  }, [allTickerSettings, fund]);

  return (
    <PageWrapper>
      <Grid container >
        <Grid item xs={12} className={classes.root}>
          <Grid container justifyContent="flex-start" alignItems="center" className={classes.header}>
            <Breadcrumb to={"/"} pageName={"Capital"} down={0} />
            <Grid
              xs={12}
              item
              container
              className={classes.head}
            >
              <Grid item xs={12} container direction="row" className={classes.titleBox}>
                <Typography variant={"h2"} className={classes.title}>
                  {fund?.token}
                </Typography>
                <Typography variant={"h4"} className={classes.name}>
                  {fund?.name.slice(0, 40)}
                </Typography>
                <Risk risk={fund?.riskLevel} size={36} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.infoBlock}> */}
          <Paper className={classes.infoBlock}>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Token balance
                <span className={classes.infoBlockPrefix}>{roundValue(fund?.balance * fund?.price)} USDT</span>
              </Typography>
              <Grid container alignItems="center" justifyContent="center" className={classes.infoBlockValue}>
                <Typography variant={"h3"} color="textPrimary">
                  <span className={classes.infoBlockNumber}>{fund?.balance}</span>
                  <span
                    className={clsx(
                      classes.tokenIcon,
                    )}
                  >
                    {fund?.token.slice(0, 3)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Last Reward
              </Typography>
              <Typography variant={"h3"} color="textPrimary" className={classes.infoBlockValue}>
                <span className={classes.infoBlockNumber}>{fund?.lastReward}{" "}</span>
                {fund?.asset && getTicker(fund?.asset)}
              </Typography>
            </Grid>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Total Rewards
              </Typography>
              <Typography variant={"h3"} color="textPrimary" className={classes.infoBlockValue}>
                <span className={classes.infoBlockNumber}>{fund?.totalReward}{" "}</span>
                {fund?.asset && getTicker(fund?.asset)}
              </Typography>
            </Grid>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Coin price
              </Typography>
              <Typography variant={"h3"} color="textPrimary" className={classes.infoBlockValue}>
                <span className={classes.infoBlockNumber}>{+fund?.price}{" "}</span>
                {fund?.asset && getTicker(fund?.asset)}
              </Typography>
            </Grid>
            <Grid className={classes.boxBalance}>
              <Typography variant={"h4"} className={classes.infoBlockLabel}>
                Wallet balance
              </Typography>
              <Typography variant={"h3"} color="textPrimary" className={classes.infoBlockValue}>
                <span className={classes.infoBlockNumber}>{roundValue(
                  fundUserWallet?.balance,
                  walletSettings?.minScale
                )}{" "}</span>
                {fund?.asset && getTicker(fund?.asset)}
              </Typography>
            </Grid>
          </Paper>
          {/* </Grid> */}
          <Grid>
            <Grid
              container
              spacing={4}
              style={{ paddingTop: 32, paddingBottom: 32 }}
              className={classes.contentBlocks}
            >
              <Grid item md={7} xs={12}>
                <History
                  history={operationsHistory}
                  page={page}
                  changePage={(page) => setPage(page)}
                  settings={walletSettings}
                />
              </Grid>
              <Grid item md={5} xs={12} className={classes.tradeBox}>
                <Invest
                  balance={fundUserWallet?.balance}
                  item={fund}
                  settings={walletSettings}
                  isIndexes={false}
                />
                <Sale
                  item={fund}
                  settings={walletSettings}
                  isIndexes={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.detailsBlock}>
                <Grid container>
                  <Grid item>
                    <Typography
                      variant={"h4"}
                      className={classes.blockTitle}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item className={classes.description}>
                    <Typography
                      variant={"body2"}
                      color="textSecondary"
                      align="justify"
                      noWrap={false}
                    >
                      {fund?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default FundDetails;

export const useStylesCardDetails = makeStyles((theme) => ({
  root: {
    padding: "19px 0px",
  },
  paper: {
    width: "100%",
    background: theme.palette.background.header,
    padding: 20,
  },
  header: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column'
    },
  },
  head: {
    flex: 1,
    marginLeft: 40,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: 24,
      marginBottom: 12
    },
  },
  titleBox: {
    alignItems: 'flex-end',
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'center'
    }
  },
  title: {
    fontSize: 44,
    textTransform: "uppercase",
    fontWeight: 800,
    minWidth: 90
  },
  name: {
    textTransform: 'uppercase',
    fontWeight: 700,
    marginLeft: 16,
    marginRight: 50
  },
  infoBlock: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 16px 12px 16px',
    justifyContent: 'space-around',
    marginTop: 32
  },
  infoBlockLabel: {
    fontWeight: 400,
    textTransform: 'uppercase',
    paddingBottom: 22,
    position: 'relative'
  },
  infoBlockValue: {
    fontWeight: 300,
    fontSize: 14
  },
  infoBlockNumber: {
    fontSize: 32,
    fontWeight: 700,
  },
  infoBlockPrefix: {
    position: 'absolute',
    top: 20,
    right: 0,
    color: theme.palette.text.quaternary,
    fontSize: 10,
    fontWeight: 300
  },
  imgWrap: {
    width: 40,
    paddingTop: 4,
  },
  informationImg: {
    width: 20,
    height: 20,
  },
  description: {
    width: "calc(100% - 40px)",
  },
  boxBalance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 12,
    padding: 8
  },
  tradeBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  tokenIcon: {
    fontSize: 12,
    color: theme.palette.text.tertiary,
    fontWeight: 700,
    display: "inline",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
  },
  LOW: {
    backgroundColor: "rgba(244, 229, 88, 1)",
  },
  MEDIUM: {
    backgroundColor: "rgba(248, 177, 51, 1)",
  },
  HIGH: {
    backgroundColor: "rgba(233, 90, 12, 1)",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  detailsBlock: {
    background: theme.palette.background.paper,
    padding: '32px 24px',
  },
  blockTitle: {
    color: theme.palette.text.quaternary,
    marginBottom: 24
  },
  contentBlocks: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column-reverse'
    },
  }
}));
