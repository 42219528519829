import SupportIcon from "../../assets/icons/menu/support.svg";
import PrivacyPolicyIcon from "../../assets/icons/menu/privacy_policy.svg";
import TermOfUseIcon from "../../assets/icons/menu/terms_of_use.svg";
import Capital from "../../assets/icons/menu/capital.svg";
import SupportIcon_Active from "../../assets/icons/menu/active/support_active.svg";
import PrivacyPolicyIcon_Active from "../../assets/icons/menu/active/privacy_policy_active.svg";
import TermOfUseIcon_Active from "../../assets/icons/menu/active/terms_of_use_active.svg";
import Capital_Active from "../../assets/icons/menu/active/capital_active.svg";

export const EXTERNAL_WALLET_APP_ROUTE_PATH_NAME = 'wallet'

export const MENU_LIST = [
  {
    title: "Capital",
    path: "/",
    activeSrc: Capital_Active,
    altImg: "capital",
    src: Capital,
  },
  {
    title: "Support",
    path: "/support",
    activeSrc: SupportIcon_Active,
    altImg: "support",
    src: SupportIcon,
  },
  {
    title: "Privacy Policy",
    path: "/privacy",
    activeSrc: PrivacyPolicyIcon_Active,
    altImg: "privacy_policy",
    src: PrivacyPolicyIcon,
  },
  {
    title: "Terms of Use",
    path: "/terms",
    activeSrc: TermOfUseIcon_Active,
    altImg: "term_of_use",
    src: TermOfUseIcon,
  },
];

export const HEADER_NAV_LIST = [
  {
    label: "Projects",
    href: `${EXTERNAL_WALLET_APP_ROUTE_PATH_NAME}/projects`,
  },
  {
    label: "Capital",
    href: "/",
  },
  // {
  //   label: "CryptoHarvester",
  //   href: "crypto_harvester",
  // },
  {
    label: "Wallet",
    href: EXTERNAL_WALLET_APP_ROUTE_PATH_NAME,
  },
];

export const HEADER_MOBILE_NAV_LIST = [
  {
    label: "Projects",
    href: `${EXTERNAL_WALLET_APP_ROUTE_PATH_NAME}/projects`,
  },
  {
    label: "Capital",
    href: "/",
  },
  {
    label: "Wallet",
    href: EXTERNAL_WALLET_APP_ROUTE_PATH_NAME,
  },
  {
    label: "Support",
    href: "/support",
  },
];

export const PRIVACY_TERMS_NAV_LIST = [
  {
    label: "Privacy Policy",
    href: "/privacy",
  },
  {
    label: "Terms of Use",
    href: "/terms",
  },
]

