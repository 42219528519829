import {
  GET_CURRENCIES_FAILURE,
  GET_CURRENCIES_STARTED,
  GET_CURRENCIES_SUCCESS,
  GET_WALLETS_LIST_FAILURE,
  GET_WALLETS_LIST_STARTED,
  GET_WALLETS_LIST_SUCCESS,
  GET_ALL_TICKER_SETTINGS_FAILURE,
  GET_ALL_TICKER_SETTINGS_STARTED,
  GET_ALL_TICKER_SETTINGS_SUCCESS,
  SET_WALLETS_PARAMS,
} from "../types";
import api from "../../services/api";

export const getWalletsListStarted = () => ({
  type: GET_WALLETS_LIST_STARTED,
});

export const getWalletsListSuccess = data => ({
  type: GET_WALLETS_LIST_SUCCESS,
  payload: {
    data,
  },
});

export const getWalletsListFailure = error => ({
  type: GET_WALLETS_LIST_FAILURE,
  payload: {
    error,
  },
});

export const getWalletsList = params => {
  return async dispatch => {
    dispatch(getWalletsListStarted());
    api.wallets
      .getList(params)
      .then(res => dispatch(getWalletsListSuccess(res.data)))
      .catch(error => dispatch(getWalletsListFailure(error)));
  };
};

export const setWalletsParams = params => ({
  type: SET_WALLETS_PARAMS,
  payload: {
    params,
  },
});

export const getCurrenciesStarted = () => ({
  type: GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccess = data => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: {
    data,
  },
});

export const getCurrenciesFailure = error => ({
  type: GET_CURRENCIES_FAILURE,
  payload: {
    error,
  },
});

export const getCurrencies = params => {
  return async dispatch => {
    dispatch(getCurrenciesStarted());
    api.wallets
      .getCurrencies(params)
      .then(res => dispatch(getCurrenciesSuccess(res.data)))
      .catch(error => dispatch(getCurrenciesFailure(error)));
  };
};

export const getAllTickerSettingsStarted = () => ({
  type: GET_ALL_TICKER_SETTINGS_STARTED,
});

export const getAllTickerSettingsSuccess = payload => ({
  type: GET_ALL_TICKER_SETTINGS_SUCCESS,
  payload,
});

export const getAllTickerSettingsFailure = error => ({
  type: GET_ALL_TICKER_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const getAllTickerSettings = () => {
  return async dispatch => {
    dispatch(getAllTickerSettingsStarted());
    api.wallets
      .getAllTickerSettings()
      .then(res => dispatch(getAllTickerSettingsSuccess(res.data)))
      .catch(error => dispatch(getAllTickerSettingsFailure(error)));
  };
};
