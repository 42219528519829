import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Button } from "../../../theme/components/Button";
import { OPERATION_TYPES } from "../../../utils/constants/capital";
import { useStylesCard } from "../Funds/Fund";
import { openTradeDialog } from "../../../redux/actions/dialogActions";
import Risk from "../../../theme/components/Risk";

const IndexItem = ({ item }) => {
  const classes = useStylesCard();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, allTickerSettings } = useSelector(({ wallets }) => wallets);

  const handleOpenTradeDialog = (type) => {
    dispatch(
      openTradeDialog({
        typeTrade: type,
        item: item,
        settings: walletSettings,
        userBalanceAsset: userBalanceAsset,
        isIndexes: true,
      })
    );
  };

  const walletSettings = useMemo(() => {
    return allTickerSettings?.find(
      (i) => i.ticker === (item?.asset || "usdt-trc20")
    );
  }, [allTickerSettings, item]);

  const userBalanceAsset = useMemo(() => {
    return +data?.find((i) => i.ticker === (item?.asset || "usdt-trc20"))
      ?.balance;
  }, [data, item]);

  return (
    <>
      <Paper elevation={4} className={classes.root}>
        <Grid container>
          <Grid
            container
            className={classes.head}
            onClick={() => history.push(`/capital/index/${item?.id}`)}
          >
            <Grid item xs={7} sm={6} container direction="row" className={classes.titleBox}>
              <Typography variant={"h2"} className={classes.title}>
                {item?.token}
              </Typography>
              <Typography variant={"h4"} className={classes.name} >
                {item?.name}
              </Typography>
            </Grid>
            <Grid item xs={5} sm={6} container alignItems="flex-end" justifyContent="center">
              <Risk risk={item?.riskLevel} />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography className={classes.labelText} variant={"caption"} color="textSecondary">
              {"Available coins"}
            </Typography>
            <Typography className={classes.valueText} variant={"subtitle2"} color="textPrimary">
              <span className={classes.numberText}>{item?.availableTokens}</span>
            </Typography>
          </Grid>
          <Grid item xs={4}  alignItems="center" container direction="column">
            <Typography className={classes.labelText} variant={"caption"} color="textSecondary">
              {"Market Cap"}
            </Typography>
            <Typography className={classes.valueText} variant={"subtitle2"} color="textPrimary">
              <span className={classes.numberText}>{item?.capitalization}</span> $
            </Typography>
          </Grid>
          <Grid item xs={4}  alignItems="center" container direction="column">
            <Typography className={classes.labelText} variant={"caption"} color="textSecondary">
              {"Current balance"}
            </Typography>
            <div className={classes.row}>
              <Typography className={classes.valueText}>
                <span className={classes.numberText}>{item?.balance}{" "}</span>
                {item?.token.slice(0, 3)}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.contentBox}>
          <Grid item>
            <Button
              className={clsx(classes.button, classes.btnLabelColor)}
              variant="contained"
              onClick={() => handleOpenTradeDialog(OPERATION_TYPES.BUY_TOKEN)}
            >
              {"BUY"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={clsx(classes.button, classes.btnLabelColor)}
              variant="contained"
              onClick={() => handleOpenTradeDialog(OPERATION_TYPES.SELL_TOKEN)}
            >
              {"SALE REQUEST"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default IndexItem;
