import { CLOSE_DIALOG, OPEN_DIALOG } from "../types";

export const openTradeDialog = (payload) => ({
  type: OPEN_DIALOG.TRADE,
  payload,
});

export const closeTradeDialog = () => ({
  type: CLOSE_DIALOG.TRADE,
});

// export const openReceiveDialog = (payload) => ({
//   type: OPEN_DIALOG.RECEIVE,
//   payload,
// });

// export const closeReceiveDialog = () => ({
//   type: CLOSE_DIALOG.RECEIVE,
// });

export const openInfoDialog = () => ({
  type: OPEN_DIALOG.INFO,
});

export const closeInfoDialog = () => ({
  type: CLOSE_DIALOG.INFO,
});

// export const openReplenishmentMethodDialog = (payload) => ({
//   type: OPEN_DIALOG.REPLENISHMENT_METHOD,
//   payload,
// });

// export const closeReplenishmentMethodDialog = () => ({
//   type: CLOSE_DIALOG.REPLENISHMENT_METHOD,
// });

// export const openWithdrawalMethodDialog = (payload) => ({
//   type: OPEN_DIALOG.WITHDRAWAL_METHOD,
//   payload,
// });

// export const closeWithdrawalMethodDialog = () => ({
//   type: CLOSE_DIALOG.WITHDRAWAL_METHOD,
// });

// export const openReceiveCardDialog = (payload) => ({
//   type: OPEN_DIALOG.RECEIVE_CARD,
//   payload,
// });

// export const closeReceiveCardDialog = () => ({
//   type: CLOSE_DIALOG.RECEIVE_CARD,
// });

// export const openCreateCardDialog = () => ({
//   type: OPEN_DIALOG.CREATE_CARD,
// });

// export const closeCreateCardDialog = () => ({
//   type: CLOSE_DIALOG.CREATE_CARD,
// });

export const openSuccessTradeDialog = (payload) => ({
  type: OPEN_DIALOG.SUCCESS_TRADE,
  payload,
});

export const closeSuccessTradeDialog = () => ({
  type: CLOSE_DIALOG.SUCCESS_TRADE,
});
