import {
  GET_WALLETS_LIST_FAILURE,
  GET_WALLETS_LIST_STARTED,
  GET_WALLETS_LIST_SUCCESS,
  GET_CURRENCIES_STARTED,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  GET_ALL_TICKER_SETTINGS_FAILURE,
  GET_ALL_TICKER_SETTINGS_STARTED,
  GET_ALL_TICKER_SETTINGS_SUCCESS,
  SET_WALLETS_PARAMS,
  SORT_WALLETS_LIST,
  FILTER_WALLETS_LIST,
} from "../types";

const decimalCoins = ["uaht", "usdt-trc20", "usdt-erc20"];

export default function wallets(
  state = {
    params: { number: 0, size: 10 },
    data: [],
    filteredData: [],
    currencies: [],
    allTickerSettings: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case SET_WALLETS_PARAMS:
      return { ...state, params: action.payload.params };
    case GET_WALLETS_LIST_STARTED:
    case GET_CURRENCIES_STARTED:
    case GET_ALL_TICKER_SETTINGS_STARTED:
      return { ...state, error: null };
    case GET_WALLETS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        filteredData: action.payload.data,
      };
    case SORT_WALLETS_LIST:
      return {
        ...state,
        filteredData: [...state.data].sort((a, b) =>
          action.payload.direction === "up"
            ? a[action.payload.key] > b[action.payload.key]
              ? 1
              : -1
            : a[action.payload.key] < b[action.payload.key]
            ? 1
            : -1
        ),
      };
    case FILTER_WALLETS_LIST:
      return {
        ...state,
        filteredData: [...state.data].filter(i =>
          JSON.stringify(i).includes(action.payload)
        ),
      };
    case GET_CURRENCIES_SUCCESS:
      return { ...state, currencies: action.payload.data };
    case GET_ALL_TICKER_SETTINGS_SUCCESS:
      return {
        ...state,
        allTickerSettings: action.payload.map(i => {
          if (decimalCoins.includes(i.ticker)) {
            return { ...i, minScale: 2 };
          }
          return i;
        }),
      };
    case GET_WALLETS_LIST_FAILURE:
    case GET_CURRENCIES_FAILURE:
    case GET_ALL_TICKER_SETTINGS_FAILURE:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
